import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { UsersTable } from '../Users';
import { Toolbar } from '../shared/ui';
import { AddUsersGroupModal, RemoveUsersGroupModal } from '../shared/modals';
import * as GroupsSelectors from '../../redux/reducers/groups';
import * as OauthSelectors from '../../redux/reducers/oauth';
import { GroupsActions } from '../../redux/actions';
import 'react-table/react-table.css';
import './UsersList.css';

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportIsLoading: false,
      alertText: '',
      alertColor: '',
      selected: [],
      showAddUsersModal: false,
      showRemoveUsersModal: false,
    };

    this.exportData = this.exportData.bind(this);
    this.getToolbarButtons = this.getToolbarButtons.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFilteredChange = this.handleFilteredChange.bind(this);
    this.handleSortedChange = this.handleSortedChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { groupId, users, fetchGroupsUsers } = this.props;

    if (prevProps.groupId !== groupId) {
      if (groupId !== undefined && users[groupId] === undefined) fetchGroupsUsers(groupId);
    }
  }

  getToolbarButtons() {
    const {
      groupId,
      users,
      refetchGroup,
      isLoadingUsers,
    } = this.props;
    const {
      exportIsLoading,
      selected,
    } = this.state;

    return [
      {
        name: 'Refresh',
        onClick: () => refetchGroup(groupId, users[groupId] ? Math.floor(users[groupId].offset / 20) : 0),
        disabled: isLoadingUsers,
      },
      {
        name: selected.length ? `Add (${selected.length}) to group` : `Add to group`,
        onClick: () => this.setState({ showAddUsersModal: true }),
        disabled: !selected.length,
      },
      {
        name: selected.length ? `Remove (${selected.length})` : `Remove`,
        onClick: () => this.setState({ showRemoveUsersModal: true }),
        disabled: !selected.length,
        color: 'danger',
      },
      {
        name: 'Export all data',
        onClick: this.exportData,
        disabled: exportIsLoading,
      },
    ];
  }

  toggleSelect(id) {
    const { selected } = this.state;

    if (selected.indexOf(id) > -1) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }

    this.setState({ selected: selected.slice() });
  }

  exportData() {
    const { groupId, token: { accessToken } } = this.props;

    this.setState({
      exportIsLoading: true,
      alertText: 'Generating CSV file, please wait.',
      alertColor: 'primary',
    });

    fetch(`/csvExport?groupId=${groupId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(resp => resp.blob())
      .then(blob => URL.createObjectURL(blob))
      .then((url) => {
        this.setState({
          exportIsLoading: false,
          alertText: 'CSV file generated!',
          alertColor: 'success',
        });

        setTimeout(() => this.setState({ alertText: '', alertColor: '' }), 2000);

        const link = document.createElement('a');

        link.href = url;
        link.download = `${groupId}-${new Date().toISOString()}.csv`;
        link.click();

        setTimeout(() => URL.revokeObjectURL(url), 100);
      });
  }

  clearSelected() {
    return this.setState({ selected: [] });
  }

  handlePageChange(pageIndex) {
    const { groupId, fetchGroupsUsers } = this.props;

    this.clearSelected();
    return fetchGroupsUsers(groupId, Math.ceil(pageIndex));
  }

  handleFilteredChange(params) {
    const { groupId, fetchGroupsUsers } = this.props;

    this.clearSelected();
    return fetchGroupsUsers(groupId, undefined, undefined, undefined, params.onboarding, params.tos, params.zipcode);
  }

  handleSortedChange(sortBy, orderBy) {
    const { groupId, fetchGroupsUsers } = this.props;

    this.clearSelected();
    return fetchGroupsUsers(groupId, undefined, sortBy, orderBy);
  }

  render() {
    const {
      alertColor,
      alertText,
      selected,
      showAddUsersModal,
      showRemoveUsersModal,
    } = this.state;
    const {
      groupId,
      className,
      users,
      clickHandle,
      isLoadingUsers,
    } = this.props;


    if (groupId === undefined) return null;

    const currentUsers = users[groupId] ? users[groupId].users : [];
    const filters = users[groupId] ? users[groupId].filters : {};
    const currentSortBy = users[groupId] ? users[groupId].sortBy : undefined;
    const currentOrderBy = users[groupId] ? users[groupId].orderBy : undefined;

    const currentOffset = users[groupId] ? users[groupId].offset : 0;
    const currentTotalCount = users[groupId] ? users[groupId].totalCount : 0;

    return (
      <div className={`GroupsUsersList ${className}`}>
        <AddUsersGroupModal
          users={selected}
          isOpen={showAddUsersModal}
          toggle={() => this.setState({ showAddUsersModal: false })}
        />
        <RemoveUsersGroupModal
          users={selected}
          group={groupId}
          isOpen={showRemoveUsersModal}
          toggle={() => this.setState({ showRemoveUsersModal: false })}
          onComplete={() => this.setState({ selected: [] })}
        />
        <Alert color={alertColor} isOpen={!!alertText}>
          {alertText}
        </Alert>
        <Toolbar
          className="GroupsUsersList__Toolbar"
          buttons={this.getToolbarButtons()}
        />
        <UsersTable
          users={currentUsers}
          filters={filters}
          selected={selected}
          offset={currentOffset}
          totalCount={currentTotalCount}
          onPageChange={this.handlePageChange}
          onFilteredChange={this.handleFilteredChange}
          onSortedChange={this.handleSortedChange}
          sortBy={currentSortBy}
          orderBy={currentOrderBy}
          onSelectUser={this.toggleSelect}
          onClickUser={clickHandle}
          loading={isLoadingUsers}
          className="GroupsUsersList__table"
        />
      </div>
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.object,
  clickHandle: PropTypes.func,
  className: PropTypes.string,
  groupId: PropTypes.number,
  fetchGroupsUsers: PropTypes.func.isRequired,
  isLoadingUsers: PropTypes.bool,
  refetchGroup: PropTypes.func.isRequired,
};

UsersList.defaultProps = {
  users: {},
  clickHandle: () => {},
  className: undefined,
  groupId: undefined,
  isLoadingUsers: false,
};

const mapStateToProps = state => ({
  users: GroupsSelectors.users(state),
  isLoadingUsers: GroupsSelectors.isLoadingUsers(state),
  token: OauthSelectors.getTokens(state),
});

export default connect(mapStateToProps, {
  fetchGroupsUsers: GroupsActions.fetchGroupsUsers,
  refetchGroup: GroupsActions.refetchGroup,
})(UsersList);
