import { respHandler } from '../../utils';

const { REACT_APP_NQUEUE_DOMAIN } = process.env;

const sendByGroupId = (groupId, time, { title, message, label, url }, token) => fetch(`${REACT_APP_NQUEUE_DOMAIN}/notifications`, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  method: 'POST',
  body: JSON.stringify({
    groupId,
    time,
    message: {
      title,
      body: message,
      url,
      label,
    },
  }),
}).then(respHandler);

const getMessages = (token, filter = 'all', limit = 10, offset = 0, orderBy = 'desc', sortBy = 'scheduledAt') => fetch(
  `${REACT_APP_NQUEUE_DOMAIN}/notifications?limit=${limit}&offset=${offset}&filter=${filter}&order_by=${orderBy}&sort_by=${sortBy}`,
  { headers: { Authorization: `Bearer ${token}` } },
).then(respHandler);

const getMessage = (messageId, token) => fetch(`${REACT_APP_NQUEUE_DOMAIN}/notifications/${messageId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const getMessageStatus = (messageId, token) => fetch(`${REACT_APP_NQUEUE_DOMAIN}/notifications/${messageId}/status`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const deleteMessage = (messageId, token) => fetch(`${REACT_APP_NQUEUE_DOMAIN}/notifications/${messageId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
    method: 'DELETE',
  },
});

export {
  sendByGroupId,
  getMessages,
  getMessage,
  getMessageStatus,
  deleteMessage,
};
