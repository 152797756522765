import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  PREPARE: '@oauth/PREPARE',
  READY: '@oauth/READY',
  SIGNIN: '@oauth/SIGNIN',
  SIGNUP: '@oauth/SIGNUP',
  SIGNOUT: '@oauth/SIGNOUT',
  SAVE_NONCE: '@oauth/SAVE_NONCE',
  EXCHANGE_TOKEN: '@oauth/EXCHANGE_TOKEN',
  EXCHANGE_TOKEN_REQUEST: '@oauth/EXCHANGE_TOKEN_REQUEST',
  EXCHANGE_TOKEN_SUCCESS: '@oauth/EXCHANGE_TOKEN_SUCCESS',
  EXCHANGE_TOKEN_FAILURE: '@oauth/EXCHANGE_TOKEN_FAILURE',
  SET_AUTHENTICATED: '@oauth/SET_AUTHENTICATED',
  UPDATE_TOKEN: '@oauth/UPDATE_TOKEN',
  SET_REFERRER: '@oauth/SET_REFERRER',
  CLEAR: '@oauth/CLEAR',
};

export const prepare = makeActionCreator(actionTypes.PREPARE);
export const ready = makeActionCreator(actionTypes.READY);
export const signIn = makeActionCreator(actionTypes.SIGNIN);
export const signUp = makeActionCreator(actionTypes.SIGNUP);
export const signOut = makeActionCreator(actionTypes.SIGNOUT);
export const saveNonce = makeActionCreator(actionTypes.SAVE_NONCE, 'nonce');
export const exchangeToken = makeActionCreator(actionTypes.EXCHANGE_TOKEN, 'nonce', 'code');
export const exchangeTokenRequest = makeActionCreator(actionTypes.EXCHANGE_TOKEN_REQUEST);
export const exchangeTokenSuccess = makeActionCreator(actionTypes.EXCHANGE_TOKEN_SUCCESS, 'token');
export const exchangeTokenFailure = makeActionCreator(actionTypes.EXCHANGE_TOKEN_FAILURE, 'error');
export const markAsAuthenticated = makeActionCreator(actionTypes.SET_AUTHENTICATED);
export const updateToken = makeActionCreator(actionTypes.UPDATE_TOKEN, 'token');
export const setReferrer = makeActionCreator(actionTypes.SET_REFERRER, 'referrer');
export const clear = makeActionCreator(actionTypes.CLEAR);
