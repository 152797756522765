import {
  takeLatest,
  all,
  select,
  call,
  put,
} from 'redux-saga/effects';
import api from '../api';
import { getTokens } from '../reducers/oauth';
import { toError } from '../../utils';
import { OidUploadActions } from '../actions';

const { actionTypes } = OidUploadActions;

function* fetchLogs(fileName) {
  try {
    yield put(OidUploadActions.fetchLogsRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.oidUpload.getLogs, fileName, accessToken);

    yield put(OidUploadActions.fetchLogsSuccess(response, fileName));
  } catch (err) {
    yield put(OidUploadActions.fetchLogsFailure(toError(err), fileName));
  }
}

function* uploadFile(file, fileName) {
  try {
    yield put(OidUploadActions.uploadFileRequest());

    const { accessToken } = yield select(getTokens);

    yield call(api.oidUpload.uploadOID, file, fileName, accessToken);

    yield put(OidUploadActions.uploadFileSuccess());
  } catch (err) {
    yield put(OidUploadActions.uploadFileFailure(toError(err)));
  }
}

function* performByAction(action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_LOGS:
      return yield fetchLogs(payload.fileName);
    case actionTypes.UPLOAD_FILE:
      return yield uploadFile(payload.file, payload.fileName);
    default:
      return null;
  }
}

function* oidUploadSaga() {
  yield all([
    takeLatest([
      actionTypes.FETCH_LOGS,
      actionTypes.UPLOAD_FILE,
    ], performByAction),
  ]);
}

export default oidUploadSaga;
