import { respHandler } from '../../utils';

const { REACT_APP_OLIVINE_DOMAIN } = process.env;

const getUser = (userId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/users/${userId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const getGroups = token => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/groups`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const getGroupsUsers = (groupId, token, limit = 20, offset = 0, sortBy = 'id', orderBy = 'asc', onboarding, tos, zipcode) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/groups/${groupId}/users?limit=${limit}&offset=${offset}&sort_by=${sortBy}&order_by=${orderBy}${onboarding !== undefined ? `&onboarding=${onboarding}` : ''}${tos !== undefined ? `&tos=${tos}` : ''}${zipcode !== undefined ? `&zipcode=${zipcode}` : ''}`,
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(respHandler);

const getUserAnalytics = (userId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/users/${userId}/analytics`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const removeFromGroup = (userId, groupId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/groups/${groupId}/users/${userId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  method: 'DELETE',
}).then(resp => respHandler(resp, true));

const addToGroup = (userId, groupId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/groups/${groupId}/users`, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  method: 'POST',
  body: JSON.stringify({ userId }),
}).then(resp => respHandler(resp, true));

const createGroup = (name, tos, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/groups`, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  method: 'POST',
  body: JSON.stringify({ name, tos }),
}).then(respHandler);

const updateGroup = (groupId, name, tos, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/groups/${groupId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  method: 'PATCH',
  body: JSON.stringify({ name, tos }),
}).then(respHandler);

const getGroupsDrEvents = (groupId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/groups/${groupId}/dr/events`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const postNewEvent = (groups, startTime, endTime, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/dr/events`, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  method: 'POST',
  body: JSON.stringify({
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    groups,
  }),
}).then(respHandler);

const postEventToIFTTT = (groups, startTime) => {
  const eta = Math.floor(new Date(startTime).getTime() / 1000);
  const groupId = groups[0];
  console.log('eta', eta);
  console.log('groupId', groupId);
  fetch(`https://enertalk-ifttt.appspot.com/createTask?eta=${eta}&groupId=${groupId}`, {
    method: 'POST',
  }).then(respHandler);
};

const deleteEvent = (eventId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/dr/events/${eventId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  method: 'DELETE',
}).then(respHandler);

const getEvent = (eventId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/dr/events/${eventId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const getEventUsers = (eventId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/dr/events/${eventId}/users`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const getUserEventPerformance = (userId, eventId, token) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/users/${userId}/dr/events/${eventId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(respHandler);

const getAllUsers = (token, limit = 20, offset = 0, sortBy = 'id', orderBy = 'asc', onboarding, tos, zipcode) => fetch(`${REACT_APP_OLIVINE_DOMAIN}/olivine-dr/users?limit=${limit}&offset=${offset}&sort_by=${sortBy}&order_by=${orderBy}${onboarding !== undefined ? `&onboarding=${onboarding}` : ''}${tos !== undefined ? `&tos=${tos}` : ''}${zipcode !== undefined ? `&zipcode=${zipcode}` : ''}`,
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(respHandler);

export {
  getUser,
  getGroups,
  getGroupsUsers,
  getUserAnalytics,
  removeFromGroup,
  addToGroup,
  createGroup,
  updateGroup,
  getGroupsDrEvents,
  postNewEvent,
  postEventToIFTTT,
  deleteEvent,
  getEvent,
  getEventUsers,
  getUserEventPerformance,
  getAllUsers,
};
