import { actionTypes } from '../actions/messages';

function MessagesReducer(state = {}, action) {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.SEND_MESSAGE_REQUEST:
    case actionTypes.FETCH_MESSAGES_REQUEST:
    case actionTypes.FETCH_MESSAGE_REQUEST:
    case actionTypes.FETCH_MESSAGE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SEND_MESSAGE_FAILURE:
    case actionTypes.FETCH_MESSAGES_FAILURE:
    case actionTypes.FETCH_MESSAGE_FAILURE:
    case actionTypes.FETCH_MESSAGE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case actionTypes.FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        [`${payload.filter}MessagesList`]: payload.messages,
      };
    case actionTypes.FETCH_MESSAGE_SUCCESS:
      if (!state.messages) {
        return {
          ...state,
          loading: false,
          messages: {
            [payload.message.id]: payload.message,
          },
        };
      }

      return {
        ...state,
        loading: false,
        messages: {
          ...state.messages,
          [payload.message.id]: payload.message,
        },
      };
    case actionTypes.FETCH_MESSAGE_STATUS_SUCCESS:
      if (!state.statuses) {
        return {
          ...state,
          loading: false,
          statuses: {
            [payload.status.statuses[0].message]: payload.status,
          },
        };
      }

      return {
        ...state,
        loading: false,
        statuses: {
          ...state.statuses,
          [payload.status.statuses[0].message]: payload.status,
        },
      };
    default:
      return state;
  }
}

export default MessagesReducer;
export const isLoading = state => state.messages.loading;
export const error = state => state.messages.error;

export const getRawPastMessages = state => state.messages.pastMessagesList || {};
export const getRawUpcomingMessages = state => state.messages.upcomingMessagesList || {};

export const pastMessages = state => (state.messages.pastMessagesList ? state.messages.pastMessagesList.notifications : []);
export const upcomingMessages = state => (state.messages.upcomingMessagesList ? state.messages.upcomingMessagesList.notifications : []);

export const messages = state => state.messages.messages || {};
export const statuses = state => state.messages.statuses || {};

export const getPastTotalPages = state => (state.messages.pastMessagesList ? Math.ceil(state.messages.pastMessagesList.totalCount / state.messages.pastMessagesList.limit) : 0);
export const getPastPage = state => (state.messages.pastMessagesList ? Math.ceil((state.messages.pastMessagesList.offset + 1) / state.messages.pastMessagesList.limit) : 0);

export const getUpcomingTotalPages = state => (state.messages.upcomingMessagesList ? Math.ceil(state.messages.upcomingMessagesList.totalCount / state.messages.upcomingMessagesList.limit) : 0);
export const getUpcomingPage = state => (state.messages.upcomingMessagesList ? Math.ceil((state.messages.upcomingMessagesList.offset + 1) / state.messages.upcomingMessagesList.limit) : 0);
