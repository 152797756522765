import {
  all,
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { UiActions } from '../actions';
import * as UiSelectors from '../reducers/ui';

const { actionTypes } = UiActions;

function* toggleSidenav() {
  const isCollapsed = yield select(UiSelectors.SideNav.isCollapsed);

  yield put(UiActions.setSidenav(!isCollapsed));
}

function* performByAction(action) {
  const { type } = action;

  switch (type) {
    case actionTypes.TOGGLE_SIDENAV:
      return yield call(toggleSidenav);
    default:
      return null;
  }
}

function* uiSaga() {
  yield all([
    takeEvery([
      actionTypes.TOGGLE_SIDENAV,
    ], performByAction),
  ]);
}

export default uiSaga;
