import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.css';

const Tabs = ({ tabs, onChange, selectedTab, className }) => (
  <div className={`Tabs${className ? ` ${className}` : ''}`}>
    { tabs.map((tab, i) => (
      <div
        className={`tab${i === selectedTab ? ' active' : ''}`}
        key={tab}
        onClick={() => onChange(i)}
        onKeyPress={() => onChange(i)}
        role="button"
        tabIndex={0}
      >
        {tab}
      </div>
    )) }
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Tabs.defaultProps = {
  className: '',
};

export default Tabs;
