import * as enertalk from './enertalk';
import * as oauth from './oauth';
import * as olivineDr from './olivineDr';
import * as amiAdapter from './amiAdapter';
import * as messages from './messages';
import * as oidUpload from './oidUpload';

const api = {
  enertalk,
  oauth,
  olivineDr,
  amiAdapter,
  messages,
  oidUpload,
};

export default api;
