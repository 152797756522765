import expiredSession from './expiredSession';

class EnerTalkError extends Error {
  constructor(name = '', message = '') {
    super(message || name);

    this.name = name;
    this.message = message;
  }

  toString() {
    return `${this.name}: ${this.message}`;
  }
}

function toError(error) {
  // axios error object
  if (error.response) {
    if (error.response.status === 401) expiredSession();

    const { statusText, data } = error.response;
    const message = data.message || data.errorDescription || JSON.stringify(data);
    return new EnerTalkError(statusText, message);
  }

  // fetch error object
  if (error.status) {
    if (error.status === 401 || error.status === 403) expiredSession();

    return new EnerTalkError(error.status, error.statusText);
  }

  // Common Error instance
  if (error.message) {
    return new EnerTalkError(error.name, error.message);
  }

  if (typeof error === 'string') {
    return new EnerTalkError('error', error);
  }

  throw new Error(`invalid error type: ${typeof error}`);
}

export default toError;
export { EnerTalkError };
