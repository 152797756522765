import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  Router,
} from 'react-router-dom';
import {
  MessagesHome,
  MessagesDetailed,
} from '../../containers';

import './Messages.css';

class Messages extends Component {
  render() {
    const { history } = this.props;

    return (
      <div className="Messages">
        <Router history={history}>
          <Switch>
            <Route exact path="/messages" component={MessagesHome} />
            <Route path="/messages/:messageId" component={MessagesDetailed} />
          </Switch>
        </Router>
      </div>
    );
  }
}

Messages.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Messages;
