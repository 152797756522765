import React from 'react';
import PropTypes from 'prop-types';
import './SelectBox.css';

const SelectBox = ({ selected }) => <div className={`SelectBox${selected ? ' selected' : ''}`} />;

SelectBox.propTypes = {
  selected: PropTypes.bool.isRequired,
};

export default SelectBox;
