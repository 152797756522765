import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Button } from '../shared/ui';
import { AddUsersGroupModal, RemoveUsersGroupModal } from '../shared/modals';
import * as UsersSelectors from '../../redux/reducers/users';
import Loading from '../Loading';
import './UserGroups.css';

class UserGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: 0,
      showAddGroupModal: false,
      showRemoveGroupModal: false,
    };
  }

  render() {
    const { userId, users } = this.props;
    const { showAddGroupModal, showRemoveGroupModal, selectedGroup } = this.state;

    if (!users[userId] || !users[userId].olivine) {
      return <Loading />;
    }

    const { groups } = users[userId].olivine.profile;

    return (
      <div className="UserGroups">
        <AddUsersGroupModal
          users={[userId]}
          isOpen={showAddGroupModal}
          toggle={() => this.setState({ showAddGroupModal: false })}
        />
        <RemoveUsersGroupModal
          users={[userId]}
          group={selectedGroup}
          isOpen={showRemoveGroupModal}
          toggle={() => this.setState({ showRemoveGroupModal: false })}
        />
        <div className="header">
          <h3>
            User Groups
          </h3>
          <Button
            color="blue"
            onClick={() => this.setState({ showAddGroupModal: true })}
          >
            Add group
          </Button>
        </div>
        <Table className="GroupsTable" striped>
          <thead>
            <tr>
              <th>
                ID
              </th>
              <th>
                Name
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            { groups.map(group => (
              <tr key={group.id + group.name}>
                <th>
                  {group.id}
                </th>
                <td>
                  {group.name}
                </td>
                <td>
                  <Button
                    className="RemoveGroup"
                    color="danger"
                    onClick={() => this.setState({
                      selectedGroup: group.id,
                      showRemoveGroupModal: true,
                    })}
                  >
                    x
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

UserGroups.propTypes = {
  users: PropTypes.object,
  userId: PropTypes.number,
};

UserGroups.defaultProps = {
  users: {},
  userId: undefined,
};

const mapStateToProps = state => ({
  users: UsersSelectors.getUsers(state),
});

export default connect(mapStateToProps)(UserGroups);
