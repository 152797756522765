import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  SEND_MESSAGE: '@messages/SEND_MESSAGE',
  SEND_MESSAGE_REQUEST: '@messages/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: '@messages/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE: '@messages/SEND_MESSAGE_FAILURE',
  FETCH_MESSAGES: '@messages/FETCH_MESSAGES',
  FETCH_MESSAGES_REQUEST: '@messages/FETCH_MESSAGES_REQUEST',
  FETCH_MESSAGES_SUCCESS: '@messages/FETCH_MESSAGES_SUCCESS',
  FETCH_MESSAGES_FAILURE: '@messages/FETCH_MESSAGES_FAILURE',
  FETCH_MESSAGE: '@messages/FETCH_MESSAGE',
  FETCH_MESSAGE_REQUEST: '@messages/FETCH_MESSAGE_REQUEST',
  FETCH_MESSAGE_SUCCESS: '@messages/FETCH_MESSAGE_SUCCESS',
  FETCH_MESSAGE_FAILURE: '@messages/FETCH_MESSAGE_FAILURE',
  FETCH_MESSAGE_STATUS: '@message/FETCH_MESSAGE_STATUS',
  FETCH_MESSAGE_STATUS_REQUEST: '@message/FETCH_MESSAGE_STATUS_REQUEST',
  FETCH_MESSAGE_STATUS_SUCCESS: '@message/FETCH_MESSAGE_STATUS_SUCCESS',
  FETCH_MESSAGE_STATUS_FAILURE: '@message/FETCH_MESSAGE_STATUS_FAILURE',
  CLEAR: '@messages/CLEAR',
  FETCH_MESSAGES_NEXT_PAGE: '@message/FETCH_MESSAGES_NEXT_PAGE',
  FETCH_MESSAGES_NEXT_PAGE_REQUEST: '@message/FETCH_MESSAGES_NEXT_PAGE_REQUEST',
  FETCH_MESSAGES_PREV_PAGE: '@message/FETCH_MESSAGES_PREV_PAGE',
  FETCH_MESSAGES_PREV_PAGE_REQUEST: '@message/FETCH_MESSAGES_PREV_PAGE_REQUEST',
};

export const clear = makeActionCreator(actionTypes.CLEAR);
export const sendMessage = makeActionCreator(actionTypes.SEND_MESSAGE, 'groupId', 'message', 'time');
export const sendMessageRequest = makeActionCreator(actionTypes.SEND_MESSAGE_REQUEST);
export const sendMessageSuccess = makeActionCreator(actionTypes.SEND_MESSAGE_SUCCESS, 'status');
export const sendMessageFailure = makeActionCreator(actionTypes.SEND_MESSAGE_FAILURE, 'error');
export const fetchMessages = makeActionCreator(actionTypes.FETCH_MESSAGES, 'filter');
export const fetchMessagesRequest = makeActionCreator(actionTypes.FETCH_MESSAGES_REQUEST);
export const fetchMessagesSuccess = makeActionCreator(actionTypes.FETCH_MESSAGES_SUCCESS, 'messages', 'filter');
export const fetchMessagesFailure = makeActionCreator(actionTypes.FETCH_MESSAGES_FAILURE, 'error');
export const fetchMessage = makeActionCreator(actionTypes.FETCH_MESSAGE, 'messageId');
export const fetchMessageRequest = makeActionCreator(actionTypes.FETCH_MESSAGE_REQUEST);
export const fetchMessageSuccess = makeActionCreator(actionTypes.FETCH_MESSAGE_SUCCESS, 'message');
export const fetchMessageFailure = makeActionCreator(actionTypes.FETCH_MESSAGE_FAILURE, 'error');
export const fetchMessageStatus = makeActionCreator(actionTypes.FETCH_MESSAGE_STATUS, 'messageId');
export const fetchMessageStatusRequest = makeActionCreator(actionTypes.FETCH_MESSAGE_STATUS_REQUEST);
export const fetchMessageStatusSuccess = makeActionCreator(actionTypes.FETCH_MESSAGE_STATUS_SUCCESS, 'status');
export const fetchMessageStatusFailure = makeActionCreator(actionTypes.FETCH_MESSAGE_STATUS_FAILURE, 'error');
export const fetchMessagesNextPage = makeActionCreator(actionTypes.FETCH_MESSAGES_NEXT_PAGE, 'filter');
export const fetchMessagesNextPageRequest = makeActionCreator(actionTypes.FETCH_MESSAGES_NEXT_PAGE_REQUEST);
export const fetchMessagesPrevPage = makeActionCreator(actionTypes.FETCH_MESSAGES_PREV_PAGE, 'filter');
export const fetchMessagesPrevPageRequest = makeActionCreator(actionTypes.FETCH_MESSAGES_PREV_PAGE_REQUEST);

