import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bodymovin from 'bodymovin/build/player/bodymovin_light.min';
import vector from './vector.json';
import './Loading.css';

class Loading extends Component {
  componentDidMount() {
    if (this.target) {
      this.loader = bodymovin.loadAnimation({
        container: this.target,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: vector,
      });
    }
  }

  render() {
    return (
      <div className="Loading">
        <div
          className={`Loading__icon--${this.props.size}`}
          ref={(element) => { this.target = element; }}
        />
      </div>
    );
  }
}

Loading.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Loading.defaultProps = {
  size: 'medium',
};

export default Loading;
