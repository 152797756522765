import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UserActions, OAuthActions } from '../../redux/actions';
import * as OAuthSelectors from '../../redux/reducers/oauth';
import * as UserSelectors from '../../redux/reducers/user';
import * as UISelectors from '../../redux/reducers/ui';
import Loading from '../Loading';

class ResourceLoader extends Component {
  componentDidMount() {
    const { isReadyToFetchResource, fetchUserProfile } = this.props;

    if (isReadyToFetchResource) {
      fetchUserProfile();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchUserProfile,
      isAdmin,
      signOut,
      isReadyToFetchResource,
      loading,
    } = this.props;
    const canFetchResource = !prevProps.isReadyToFetchResource && isReadyToFetchResource;

    if (canFetchResource) {
      fetchUserProfile();
    }

    if (loading !== prevProps.loading) {
      if (isAdmin === false) {
        signOut();
      }
    }
  }


  render() {
    const {
      loading,
      children,
    } = this.props;

    if (loading) {
      return (
        <Loading />
      );
    }

    return children;
  }
}

ResourceLoader.propTypes = {
  loading: PropTypes.bool,
  isReadyToFetchResource: PropTypes.bool,
  fetchUserProfile: PropTypes.func.isRequired,
  children: PropTypes.object,
  signOut: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

ResourceLoader.defaultProps = {
  loading: true,
  isReadyToFetchResource: false,
  children: undefined,
  isAdmin: undefined,
};

function mapStateToProps(state) {
  const isReady = OAuthSelectors.isReady(state);
  const isAdmin = UserSelectors.isAdmin(state);

  return {
    loading: !isReady || UISelectors.InitialLaunch.isLoading(state),
    error: UISelectors.InitialLaunch.error(state),
    isReadyToFetchResource: isReady,
    isAdmin,
  };
}

export default connect(mapStateToProps, {
  fetchUserProfile: UserActions.fetchUserProfile,
  signOut: OAuthActions.signOut,
})(ResourceLoader);
