import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { Modal } from '../../../shared/modals';
import { GroupsSelectList } from '../../../shared/lists';
import { MessagesActions } from '../../../../redux/actions';
import * as UISelectors from '../../../../redux/reducers/ui';
import * as GroupsSelectors from '../../../../redux/reducers/groups';

class NewMessageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleInput: '',
      bodyInput: '',
      selectedGroup: null,
      inputError: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loading, error } = this.props;

    if (loading === false && prevProps.loading === true && !error) {
      this.toggle();
    }
  }

  handleChange(e) {
    return this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit() {
    const { titleInput, bodyInput, selectedGroup } = this.state;
    const { sendMessage } = this.props;

    this.setState({ inputError: '' });

    if (titleInput.length < 1) {
      this.setState({
        inputError: 'Please enter a message title',
      });
    } else if (bodyInput.length < 1) {
      this.setState({
        inputError: 'Please enter a message body',
      });
    } else if (selectedGroup === null) {
      this.setState({
        inputError: 'Please select a group',
      });
    } else {
      sendMessage(selectedGroup, {
        title: titleInput,
        message: bodyInput,
      }, new Date());
    }
  }

  toggle() {
    const { toggle } = this.props;

    this.setState({
      titleInput: '',
      bodyInput: '',
      selectedGroup: null,
      inputError: '',
    });

    return toggle();
  }

  render() {
    const {
      titleInput,
      bodyInput,
      inputError,
      selectedGroup,
    } = this.state;
    const {
      isOpen,
      error,
      loading,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen || !!loading}
        toggle={this.toggle}
        className="NewGroupModal"
        header="New Message"
        alert={inputError || error}
        alertColor="danger"
        footerButtons={[
          {
            title: 'Cancel',
            color: 'secondary',
            onClick: this.toggle,
            disabled: loading,
          },
          {
            title: 'Save',
            onClick: this.handleSubmit,
            disabled: loading,
          },
        ]}
      >
        <Form>
          <FormGroup>
            <Label>
              Title
            </Label>
            <Input type="text" value={titleInput} name="titleInput" onChange={this.handleChange} required />
          </FormGroup>
          <FormGroup>
            <Label>
              Body
            </Label>
            <Input type="text" value={bodyInput} name="bodyInput" onChange={this.handleChange} />
          </FormGroup>
          <FormGroup>
            <Label>
              Group
            </Label>
            <div>
              <GroupsSelectList
                selected={selectedGroup}
                onChange={group => this.setState({ selectedGroup: group })}
              />
            </div>
          </FormGroup>
        </Form>
      </Modal>
    );
  }
}

NewMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

NewMessageModal.defaultProps = {
  error: undefined,
  loading: undefined,
};

const mapStateToProps = state => ({
  loading: UISelectors.NewMessage.isLoading(state),
  error: UISelectors.NewMessage.error(state),
  groups: GroupsSelectors.groups(state),
});

export default connect(mapStateToProps, {
  sendMessage: MessagesActions.sendMessage,
})(NewMessageModal);
