import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  Router,
} from 'react-router-dom';
import { UsersDetails, UsersList } from '../../containers/Users';
import './Users.css';

const Users = ({ history }) => (
  <div className="Users">
    <Router history={history}>
      <Switch>
        <Route exact path="/users" component={UsersList} />
        <Route path="/users/:userId" component={UsersDetails} />
      </Switch>
    </Router>
  </div>
);

Users.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Users;
