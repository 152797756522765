import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { END } from 'redux-saga';
import middlewares, { sagaMiddleware } from './middlewares';

function configureStore(rootReducer) {
  const store = createStore(rootReducer, middlewares);
  const persistor = persistStore(store);

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return {
    store,
    persistor,
  };
}

export default configureStore;
