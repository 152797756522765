import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '../../../shared/modals';
import { DrActions } from '../../../../redux/actions';
import * as UISelectors from '../../../../redux/reducers/ui';

class DeleteDrEventModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAffected: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { loading, error } = this.props;

    if (loading === false && prevProps.loading === true && !error) {
      this.setState({
        showAffected: true,
      });
    }
  }

  render() {
    const {
      isOpen,
      loading,
      eventId,
      toggle,
      affected,
      deleteEvent,
    } = this.props;
    const {
      showAffected,
    } = this.state;

    return (
      <div>
        <Modal
          isOpen={isOpen || !!loading}
          className="DeleteDrEventWarning"
          header={`Delete DR Event ${eventId}`}
          toggle={() => toggle(false)}
          footerButtons={[
            {
              title: 'Cancel',
              color: 'secondary',
              onClick: () => toggle(false),
              disabled: !!loading,
            },
            {
              title: 'Yes',
              color: 'danger',
              onClick: () => deleteEvent(eventId),
              disabled: !!loading,
            },
          ]}
        >
          Are you sure you want to delete this event?
          <br />
          <br />
          Deleting this event will delete user performances tied to this event.
        </Modal>
        <Modal
          isOpen={showAffected}
          className="DeleteDrEventAffected"
          header={`Delete DR Event ${eventId}`}
          toggle={() => toggle(false)}
          footerButtons={[
            {
              title: 'Okay',
              onClick: () => {
                this.setState({ showAffected: false });
                toggle(false);
              },
            },
          ]}
        >
          Deleted DR event
          <br />
          <br />
          <pre className="pre-scrollable">
            {JSON.stringify(affected, null, 2)}
          </pre>
        </Modal>
      </div>
    );
  }
}

DeleteDrEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  error: PropTypes.string,
  loading: PropTypes.bool,
  affected: PropTypes.object,
};

DeleteDrEventModal.defaultProps = {
  error: undefined,
  loading: undefined,
  affected: undefined,
  eventId: undefined,
};

const mapStateToProps = state => ({
  loading: UISelectors.DeleteDrEvent.isLoading(state),
  error: UISelectors.DeleteDrEvent.error(state),
  affected: UISelectors.DeleteDrEvent.affected(state),
});

export default connect(mapStateToProps, {
  deleteEvent: DrActions.deleteEvent,
})(DeleteDrEventModal);
