import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const makeClassName = (className, color) => {
  let returnClass = 'Button';

  if (color) {
    returnClass += ` ${color}`;
  } else {
    returnClass += ' primary';
  }

  if (className) {
    returnClass += ` ${className}`;
  }

  return returnClass;
};

const Button = ({
  children,
  color,
  className,
  onClick,
  disabled,
}) => (
  <button
    className={makeClassName(className, color)}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    <div className="backgroundColor" />
    <div className="button-content">
      { children }
    </div>
  </button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  children: [],
  color: '',
  className: '',
  disabled: false,
};

export default Button;
