import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import localForage from 'localforage';
import reducers from '../redux/reducers';
import stateMigrations from './stateMigrations';

const reducersWithRouter = combineReducers({
  ...reducers,
});
const config = {
  key: `enertalkolivineadmin-${process.env.NODE_ENV}`,
  version: 1,
  storage: localForage,
  migrate: createMigrate(stateMigrations, { debug: false }),
};
const rootReducer = persistReducer(config, reducersWithRouter);

export default rootReducer;
