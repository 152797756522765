import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  Router,
  Redirect,
} from 'react-router-dom';
import {
  DrEventsList,
  DrEventOverview,
  DrEventUpload,
  DrEventUploadLogs,
} from '../../containers';
import './DREvents.css';

const DREvents = ({ history }) => (
  <div className="DREvents">
    <Router history={history}>
      <Switch>
        <Route exact path="/dr/groups" component={DrEventsList} />
        <Route path="/dr/groups/:groupId" component={DrEventsList} />
        <Route path="/dr/events/:eventId/details" component={DrEventOverview} />
        <Route path="/dr/events/:eventId/performances" component={DrEventOverview} />
        <Route exact path="/dr/upload" component={DrEventUpload} />
        <Route exact path="/dr/upload/logs" component={DrEventUploadLogs} />
        <Redirect exact path="/dr/events/:eventId" to="/dr/events/:eventId/details" />
        <Redirect exact path="/dr" to="/dr/groups" />
      </Switch>
    </Router>
  </div>
);

DREvents.propTypes = {
  history: PropTypes.object.isRequired,
};

export default DREvents;
