import { call, put, select, takeEvery } from 'redux-saga/effects';
import api from '../api';
import { UserActions } from '../actions';
import { getTokens } from '../reducers/oauth';
import { getUserId } from '../reducers/user';
import { toError } from '../../utils';

const { actionTypes } = UserActions;

function* fetchOlivineUserProfile() {
  try {
    yield put(UserActions.fetchOlivineUserProfileRequest());

    const { accessToken } = yield select(getTokens);
    const userId = yield select(getUserId);

    const response = yield call(api.olivineDr.getUser, userId, accessToken);

    yield put(UserActions.fetchOlivineUserProfileSuccess(response));
  } catch (err) {
    yield put(UserActions.fetchOlivineUserProfileFailure(toError(err)));
  }
}

function* fetchEnertalkUserProfile() {
  try {
    yield put(UserActions.fetchEnertalkUserProfileRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.enertalk.getUser, accessToken);

    yield put(UserActions.fetchEnertalkUserProfileSuccess(response));
  } catch (err) {
    yield put(UserActions.fetchEnertalkUserProfileFailure(toError(err)));
  }
}

function* fetchUserProfile() {
  yield call(fetchEnertalkUserProfile);
  yield call(fetchOlivineUserProfile);
}

function* performByAction(action) {
  const { type } = action;

  switch (type) {
    case actionTypes.FETCH_OLIVINE_USER_PROFILE:
      return yield call(fetchOlivineUserProfile);
    case actionTypes.FETCH_ENERTALK_USER_PROFILE:
      return yield call(fetchEnertalkUserProfile);
    case actionTypes.FETCH_USER_PROFILE:
      return yield call(fetchUserProfile);
    default:
      return null;
  }
}

function* olivineDrSaga() {
  yield takeEvery([
    actionTypes.FETCH_OLIVINE_USER_PROFILE,
    actionTypes.FETCH_ENERTALK_USER_PROFILE,
    actionTypes.FETCH_USER_PROFILE,
  ], performByAction);
}

export default olivineDrSaga;
