import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  CLEAR: '@dr/CLEAR',
  FETCH_GROUPS_EVENTS: '@dr/FETCH_GROUPS_EVENTS',
  FETCH_GROUPS_EVENTS_REQUEST: '@dr/FETCH_GROUPS_EVENTS_REQUEST',
  FETCH_GROUPS_EVENTS_SUCCESS: '@dr/FETCH_GROUPS_EVENTS_SUCCESS',
  FETCH_GROUPS_EVENTS_FAILURE: '@dr/FETCH_GROUPS_EVENTS_FAILURE',
  POST_NEW_EVENT: '@dr/POST_NEW_EVENT',
  POST_NEW_EVENT_REQUEST: '@dr/POST_NEW_EVENT_REQUEST',
  POST_NEW_EVENT_SUCCESS: '@dr/POST_NEW_EVENT_SUCCESS',
  POST_NEW_EVENT_FAILURE: '@dr/POST_NEW_EVENT_FAILURE',
  DELETE_EVENT: '@dr/DELETE_EVENT',
  DELETE_EVENT_REQUEST: '@dr/DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: '@dr/DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: '@dr/DELETE_EVENT_FAILURE',
  FETCH_EVENT: '@dr/FETCH_EVENT',
  FETCH_EVENT_REQUEST: '@dr/FETCH_EVENT_REQUEST',
  FETCH_EVENT_SUCCESS: '@dr/FETCH_EVENT_SUCCESS',
  FETCH_EVENT_FAILURE: '@dr/FETCH_EVENT_FAILURE',
  FETCH_EVENT_USERS: '@dr/FETCH_EVENT_USERS',
  FETCH_EVENT_USERS_REQUEST: '@dr/FETCH_EVENT_USERS_REQUEST',
  FETCH_EVENT_USERS_SUCCESS: '@dr/FETCH_EVENT_USERS_SUCCESS',
  FETCH_EVENT_USERS_FAILURE: '@dr/FETCH_EVENT_USERS_FAILURE',
  FETCH_USER_EVENT_PERFORMANCE: '@dr/FETCH_USER_EVENT_PERFORMANCE',
  FETCH_USER_EVENT_PERFORMANCE_REQUEST: '@dr/FETCH_USER_EVENT_PERFORMANCE_REQUEST',
  FETCH_USER_EVENT_PERFORMANCE_SUCCESS: '@dr/FETCH_USER_EVENT_PERFORMANCE_SUCCESS',
  FETCH_USER_EVENT_PERFORMANCE_FAILURE: '@dr/FETCH_USER_EVENT_PERFORMANCE_FAILURE',
  FETCH_ALL_EVENT_PERFORMANCE:
  '@dr/FETCH_ALL_EVENT_PERFORMANCE',
};

export const clear = makeActionCreator(actionTypes.CLEAR);
export const fetchGroupsEvents = makeActionCreator(actionTypes.FETCH_GROUPS_EVENTS, 'groupId');
export const fetchGroupsEventsRequest = makeActionCreator(actionTypes.FETCH_GROUPS_EVENTS_REQUEST);
export const fetchGroupsEventsSuccess = makeActionCreator(actionTypes.FETCH_GROUPS_EVENTS_SUCCESS, 'groupId', 'events');
export const fetchGroupsEventsFailure = makeActionCreator(actionTypes.FETCH_GROUPS_EVENTS_FAILURE, 'error');
export const postNewEvent = makeActionCreator(actionTypes.POST_NEW_EVENT, 'groups', 'startTime', 'endTime');
export const postNewEventRequest = makeActionCreator(actionTypes.POST_NEW_EVENT_REQUEST);
export const postNewEventSuccess = makeActionCreator(actionTypes.POST_NEW_EVENT_SUCCESS, 'event');
export const postNewEventFailure = makeActionCreator(actionTypes.POST_NEW_EVENT_FAILURE);
export const deleteEvent = makeActionCreator(actionTypes.DELETE_EVENT, 'eventId');
export const deleteEventRequest = makeActionCreator(actionTypes.DELETE_EVENT_REQUEST);
export const deleteEventSuccess = makeActionCreator(actionTypes.DELETE_EVENT_SUCCESS, 'eventId', 'affected');
export const deleteEventFailure = makeActionCreator(actionTypes.DELETE_EVENT_FAILURE);
export const fetchEvent = makeActionCreator(actionTypes.FETCH_EVENT, 'eventId');
export const fetchEventRequest = makeActionCreator(actionTypes.FETCH_EVENT_REQUEST);
export const fetchEventSuccess = makeActionCreator(actionTypes.FETCH_EVENT_SUCCESS, 'event');
export const fetchEventFailure = makeActionCreator(actionTypes.FETCH_EVENT_FAILURE, 'error');
export const fetchEventUsers = makeActionCreator(actionTypes.FETCH_EVENT_USERS, 'eventId');
export const fetchEventUsersRequest = makeActionCreator(actionTypes.FETCH_EVENT_USERS_REQUEST);
export const fetchEventUsersSuccess = makeActionCreator(actionTypes.FETCH_EVENT_USERS_SUCCESS, 'users', 'eventId');
export const fetchEventUsersFailure = makeActionCreator(actionTypes.FTECH_EVENT_USERS_FAILURE, 'error');
export const fetchUserEventPerformance = makeActionCreator(actionTypes.FETCH_USER_EVENT_PERFORMANCE, 'userId', 'eventId');
export const fetchUserEventPerformanceRequest = makeActionCreator(actionTypes.FETCH_USER_EVENT_PERFORMANCE_REQUEST);
export const fetchUserEventPerformanceSuccess = makeActionCreator(actionTypes.FETCH_USER_EVENT_PERFORMANCE_SUCCESS, 'performance', 'userId', 'eventId');
export const fetchUserEventPerformanceFailure = makeActionCreator(actionTypes.FETCH_USER_EVENT_PERFORMANCE_FAILURE, 'error');
export const fetchAllEventPerformnace = makeActionCreator(actionTypes.FETCH_ALL_EVENT_PERFORMANCE, 'eventId');
