import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { GroupCard } from '../../components';
import { NewGroupModal, EditGroupModal } from '../../components/Groups/modals';
import { Toolbar, Button } from '../../components/shared/ui';
import * as GroupsSelectors from '../../redux/reducers/groups';
import { GroupsActions } from '../../redux/actions';
import './GroupsHome.css';

class GroupsHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewGroupModal: false,
      showEditGroupModal: false,
      selected: {},
    };

    this.renderCards = this.renderCards.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.toggleGroup = this.toggleGroup.bind(this);
    this.toggleNewGroupModal = this.toggleNewGroupModal.bind(this);
    this.toggleEditGroupModal = this.toggleEditGroupModal.bind(this);
    this.getToolbarButtons = this.getToolbarButtons.bind(this);
  }

  getToolbarButtons() {
    const { selected } = this.state;
    const { fetchGroupsUsersShallow } = this.props;

    const selectedCount = Object.values(selected).filter(val => val === true).length;

    const optionalButtons = [];

    if (selectedCount === 1) {
      optionalButtons.push({
        name: 'Edit Group',
        color: 'secondary',
        onClick: this.toggleEditGroupModal,
      });
    }

    if (selectedCount > 0) {
      optionalButtons.push({
        name: `Remove (${selectedCount})`,
        color: 'danger',
        onClick: () => {},
      });
    }

    return [
      ...optionalButtons,
      {
        name: 'Refresh',
        onClick: fetchGroupsUsersShallow,
      },
      {
        name: 'New Group',
        onClick: () => this.toggleNewGroupModal(true),
      },
    ];
  }

  toggleGroup(groupId) {
    const { selected } = this.state;

    this.setState({
      selected: {
        ...selected,
        [groupId]: !selected[groupId],
      },
    });
  }

  selectGroup(groupId) {
    const { history } = this.props;

    return history.push(`/groups/${groupId}`);
  }

  toggleNewGroupModal(bool) {
    if (typeof bool === 'boolean') {
      return this.setState({
        showNewGroupModal: bool,
      });
    }

    const { showNewGroupModal } = this.state;

    return this.setState({
      showNewGroupModal: !showNewGroupModal,
    });
  }

  toggleEditGroupModal(bool) {
    if (typeof bool === 'boolean') {
      return this.setState({
        showEditGroupModal: bool,
      });
    }

    const { showEditGroupModal } = this.state;

    return this.setState({
      showEditGroupModal: !showEditGroupModal,
    });
  }

  renderCards() {
    const { selected } = this.state;
    const { groups, users } = this.props;

    return (
      <div className="GroupsHome__cards">
        { groups.map(group => (
          <GroupCard
            key={group.id}
            id={group.id}
            name={group.name}
            usersCount={users[group.id] ? users[group.id].totalCount : 0}
            onClick={() => this.selectGroup(group.id)}
            onToggle={() => this.toggleGroup(group.id)}
            selected={selected[group.id]}
          />)) }
      </div>
    );
  }

  render() {
    const { showNewGroupModal, showEditGroupModal, selected } = this.state;
    const current = Object.keys(selected).find(key => selected[key] === true);

    return (
      <div className="GroupsHome">
        <Toolbar
          className="GroupsHome__toolbar"
          left={(
            <div className="search">
              <Input className="search-input" disabled />
              <Button className="submit-search" color="blue" onClick={() => {}} disabled>
                Search
              </Button>
            </div>
          )}
          buttons={this.getToolbarButtons()}
        />
        {this.renderCards()}
        <NewGroupModal isOpen={showNewGroupModal} toggle={this.toggleNewGroupModal} />
        <EditGroupModal
          isOpen={showEditGroupModal}
          toggle={this.toggleEditGroupModal}
          group={Number(current)}
        />
      </div>
    );
  }
}

GroupsHome.propTypes = {
  history: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  groups: GroupsSelectors.groups(state),
  users: GroupsSelectors.users(state),
  isLoading: GroupsSelectors.isLoading(state),
});

export default connect(mapStateToProps, {
  fetchGroupsUsersShallow: GroupsActions.fetchGroupsUsersShallow,
})(GroupsHome);
