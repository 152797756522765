import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { SimpleDataTable } from '../shared/ui';
import * as UsersSelectors from '../../redux/reducers/users';
import Loading from '../Loading';
import './UserProfile.css';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.renderProfile = this.renderProfile.bind(this);
    this.renderUtilityInfo = this.renderUtilityInfo.bind(this);
    this.renderOnboardingInfo = this.renderOnboardingInfo.bind(this);
  }

  renderProfile() {
    const { users, userId } = this.props;
    const { enertalk, olivine = {} } = users[userId];

    if (!enertalk || !olivine.profile) return <Loading />;

    return (
      <SimpleDataTable
        header="User Info"
        data={[
          {
            title: 'Name',
            content: enertalk.name,
          },
          {
            title: 'Email',
            content: enertalk.email,
          },
          {
            title: 'Zipcode',
            content: olivine.profile.zipcode,
          },
          {
            title: 'Groups',
            content: olivine.profile.groups.map(group => `${group.id} - ${group.name}`).join(', '),
          },
          {
            title: 'EnerTalk User ID',
            content: enertalk.user_id,
          },
        ]}
      />
    );
  }

  renderUtilityInfo() {
    const { users, userId } = this.props;
    const { integration } = users[userId];

    if (!integration) return undefined;

    return (
      <Fragment>
        <SimpleDataTable
          header="Utility Info"
          data={[
            {
              title: 'Utility',
              content: integration.displayName,
            },
            {
              title: 'Usage Point',
              content: integration.usagePointId,
            },
          ]}
        />
        <SimpleDataTable
          header="AMI Adapter Info"
          data={[
            {
              title: 'Integration ID',
              content: integration.integrationId,
            },
            {
              title: 'Status',
              content: integration.status,
            },
            {
              title: 'EnerTalk Site ID',
              content: integration.siteId,
            },
            {
              title: 'EnerTalk Device ID',
              content: integration.deviceId,
            },
            {
              title: 'EnerTalk Team ID',
              content: integration.teamId,
            },
            {
              title: 'Created At',
              content: new Date(integration.createdAt).toLocaleString(),
            },
            {
              title: 'Last Received Data At',
              content: new Date(integration.lastDataTime).toLocaleString(),
            }
          ]}
        />
      </Fragment>
    );
  }

  renderOnboardingInfo() {
    const { users, userId } = this.props;
    const { olivine = {} } = users[userId];

    if (!olivine.analytics) return <Loading />;

    return (
      <SimpleDataTable
        className="OnboardingInfo"
        header="Onboarding Analytics"
        data={[
          {
            title: 'Account Created At',
            content: new Date(olivine.analytics.createdAt).toLocaleString(),
          },
          {
            title: 'Last Onboarding Step At',
            content: new Date(olivine.analytics.updatedAt).toLocaleString(),
          },
          {
            title: 'Terms of Service',
            content: (() => {
              const { tos } = olivine.analytics;

              switch (tos) {
                case 1:
                  return 'Rejected';
                case 2:
                  return 'Accepted';
                default:
                  return 'N/A';
              }
            })(),
          },
          {
            title: 'Onboarding Event History',
            content: (
              <div className="EventList">
                <Table size="sm" striped>
                  <thead>
                    <tr>
                      <th>
                        Event
                      </th>
                      <th>
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    { olivine.analytics.events.map(event => (
                      <tr key={event.name + event.createdAt}>
                        <td>
                          { event.name }
                        </td>
                        <td>
                          { new Date(event.createdAt).toLocaleString() }
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </Table>
              </div>
            ),
          },
        ]}
      />
    );
  }

  render() {
    const { users, userId } = this.props;

    if (!users[userId]) return <Loading />;

    return (
      <div className="UserProfile">
        { this.renderProfile() }
        { this.renderOnboardingInfo() }
        { this.renderUtilityInfo() }
      </div>
    );
  }
}

UserProfile.propTypes = {
  users: PropTypes.object,
  userId: PropTypes.number,
};

UserProfile.defaultProps = {
  users: {},
  userId: undefined,
};

const mapStateToProps = state => ({
  users: UsersSelectors.getUsers(state),
});

export default connect(mapStateToProps)(UserProfile);
