import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import * as UiSelectors from '../../redux/reducers/ui';
import { ExpandingSideNav } from '../../components/Nav';
import './SideNavBar.css';

function SideNavBar({ isCollapsed }) {
  return (
    <div className={`SideNavBar${isCollapsed ? ' collapsed' : ''}`}>
      <Link to="/" className="SideNavBar__title" />
      <div className="SideNavBar__items">
        <NavLink
          to="/groups"
          className="SideNavBar__item"
          activeClassName="active"
        >
          <div className="SideNavBar__itemLogo groups" />
          <div className="SideNavBar__itemTitle">
            Groups
          </div>
        </NavLink>
        <NavLink
          to="/users"
          className="SideNavBar__item"
          activeClassName="active"
        >
          <div className="SideNavBar__itemLogo users" />
          <div className="SideNavBar__itemTitle">
            Users
          </div>
        </NavLink>
        <NavLink
          to="/messages"
          className="SideNavBar__item"
          activeClassName="active"
        >
          <div className="SideNavBar__itemLogo messages" />
          <div className="SideNavBar__itemTitle">
            Messages
          </div>
        </NavLink>
        <ExpandingSideNav
          mainTo="/dr"
          className="dr"
          name="Demand Response"
          subNavs={[
            {
              to: '/dr/groups',
              name: 'Groups',
              className: 'groups',
            },
            {
              to: '/dr/upload',
              name: 'Upload Performance',
              className: 'uploadPerformance',
            },
          ]}
        />
      </div>
    </div>
  );
}

SideNavBar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isCollapsed: UiSelectors.SideNav.isCollapsed(state),
});

export default connect(mapStateToProps)(SideNavBar);
