import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import Datetime from 'react-datetime';
import { Modal } from '../../../shared/modals';
import { DrActions } from '../../../../redux/actions';
import * as UISelectors from '../../../../redux/reducers/ui';
import 'react-datetime/css/react-datetime.css';

class NewDrEventModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startTimeInput: undefined,
      endTimeInput: undefined,
      inputError: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this); 
  }

  componentDidUpdate(prevProps) {
    const { loading, error } = this.props;

    if (loading === false && prevProps.loading === true && !error) {
      this.toggle();
    }
  }

  handleChange(date, name) {
    return this.setState({
      [name]: date,
    });
  }

  handleSubmit() {
    const { startTimeInput, endTimeInput } = this.state;
    const { postNewEvent, groupId } = this.props;

    this.setState({ inputError: '' });

    if (!startTimeInput || !endTimeInput) {
      this.setState({
        inputError: 'Please enter a start or end time',
      });
    } else {
      postNewEvent([groupId], new Date(startTimeInput), new Date(endTimeInput));
    }
  }

  toggle() {
    const { toggle } = this.props;

    this.setState({
      startTimeInput: undefined,
      endTimeInput: undefined,
      inputError: '',
    });

    return toggle();
  }

  render() {
    const {
      startTimeInput,
      endTimeInput,
      inputError,
    } = this.state;
    const {
      isOpen,
      error,
      loading,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen || !!loading}
        toggle={this.toggle}
        className="NewDrEventModal"
        header="New DR Event"
        alert={inputError || error}
        alertColor="danger"
        footerButtons={[
          {
            title: 'Cancel',
            color: 'secondary',
            onClick: this.toggle,
            disabled: !!loading,
          },
          {
            title: 'Save',
            onClick: this.handleSubmit,
            disabled: !!loading,
          },
        ]}
      >
        <Form>
          <FormGroup>
            <Label>
              Start Time
            </Label>
            <Datetime value={startTimeInput} onChange={(date) => this.handleChange(date, 'startTimeInput')} />
          </FormGroup>
          <FormGroup>
            <Label>
              End Time
            </Label>
            <Datetime value={endTimeInput} onChange={(date) => this.handleChange(date, 'endTimeInput')} />
          </FormGroup>
        </Form>
      </Modal>
    );
  }
}

NewDrEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  postNewEvent: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

NewDrEventModal.defaultProps = {
  error: undefined,
  loading: undefined,
};

const mapStateToProps = state => ({
  loading: UISelectors.NewDrEvent.isLoading(state),
  error: UISelectors.NewDrEvent.error(state),
});

export default connect(mapStateToProps, {
  postNewEvent: DrActions.postNewEvent,
})(NewDrEventModal);
