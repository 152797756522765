import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import './Toolbar.css';

const Toolbar = ({
  className,
  left,
  right,
  buttons,
  children,
}) => (
  <div
    className={`Toolbar${!left && !right ? ' buttons-only' : ''}${className ? ` ${className}` : ''}`}
  >
    { left ? (
      <div className="left">
        { left }
      </div>
    ) : undefined }
    { children }
    { buttons ? (
      <div className="buttons">
        { buttons.map(button => (React.isValidElement(button) ? button : (
          <Button
            key={button.name + button.color}
            onClick={button.onClick}
            color={button.color || 'primary'}
            disabled={button.disabled}
          >
            { button.name }
          </Button>
        ))) }
      </div>
    ) : undefined }
    { right ? (
      <div className="right">
        { right }
      </div>
    ) : undefined }
  </div>
);

Toolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  left: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
    PropTypes.node,
  ])),
};

Toolbar.defaultProps = {
  children: [],
  className: '',
  left: undefined,
  right: undefined,
  buttons: undefined,
};

export default Toolbar;
