import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UsersList } from '../../components';

class GroupsUserlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: Number(props.match.params.groupId),
    };

    this.selectUser = this.selectUser.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (match.params.groupId !== prevProps.match.params.groupId) {
      this.setState({ selectedGroup: Number(match.params.groupId) });
    }
  }

  selectUser(userId) {
    const { history } = this.props;

    return history.push(`/users/${userId}`);
  }

  render() {
    const { selectedGroup } = this.state;

    return <UsersList groupId={selectedGroup} clickHandle={this.selectUser} />;
  }
}

GroupsUserlist.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default GroupsUserlist;
