import { actionTypes } from '../actions/oauth';
import { crypto } from '../helpers';

function OAuthReducer(state = {}, action) {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case actionTypes.PREPARE:
      return {
        ...state,
        isReady: false,
      };
    case actionTypes.READY:
      return {
        ...state,
        isReady: true,
      };
    case actionTypes.SIGNIN:
      return {
        ...state,
        nonce: null,
        error: null,
        token: null,
      };
    case actionTypes.SAVE_NONCE:
      return {
        ...state,
        nonce: payload.nonce,
      };
    case actionTypes.EXCHANGE_TOKEN_REQUEST:
      return {
        ...state,
        codeExchanging: true,
        error: null,
      };
    case actionTypes.EXCHANGE_TOKEN_SUCCESS:
      return {
        ...state,
        codeExchanging: false,
        nonce: null,
        token: payload.token,
      };
    case actionTypes.EXCHANGE_TOKEN_FAILURE:
      return {
        ...state,
        codeExchanging: false,
        error: payload.error.toString(),
        token: null,
      };
    case actionTypes.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case actionTypes.UNSET_AUTHENTICATED:
      return {
        ...state,
        authenticated: false,
      };
    case actionTypes.UPDATE_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    case actionTypes.REMOVE_TOKEN:
      return {
        ...state,
        token: null,
      };
    case actionTypes.SET_REFERRER:
      return {
        ...state,
        referrer: payload.referrer,
      };
    case actionTypes.CLEAR:
      return {
        codeExchanging: false,
        error: null,
        nonce: null,
        referrer: null,
        token: null,
        authenticated: false,
      };
    default:
      return state;
  }
}

export default OAuthReducer;

export const authenticated = state => Boolean(state.oauth.authenticated);
export const hasNonce = state => Boolean(state.oauth.nonce);
export const getNonce = state => state.oauth.nonce;
export const getTokens = (state) => {
  const { token } = state.oauth;

  if (!token) {
    return {};
  }

  return crypto.decrypt(process.env.REACT_APP_CRYPTO_SECRET, token);
};
export const getError = state => state.oauth.error;
export const getReferrer = state => state.oauth.referrer;
export const isReady = state => state.oauth.isReady;
