import { store } from '../store';
import * as OAuthActions from '../redux/actions/oauth';

let called;

const expiredSession = () => {
  const { oauth: { token } } = store.getState();

  if (token && (!called || Date.now() - 15000 > called)) {
    alert('Your session has expired. Please login again.');

    called = Date.now();

    store.dispatch(OAuthActions.signOut());
  }
};

export default expiredSession;
