const { REACT_APP_ENERTALK_LEGACY_API_DOMAIN, REACT_APP_BACKEND_DOMAIN = '' } = process.env;

const getUser = token => fetch(`${REACT_APP_ENERTALK_LEGACY_API_DOMAIN}/1.2/users/basicinfo`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then((resp) => {
  if (!resp.ok) throw resp;
  return resp.json();
});

const getUserInfo = (userId, token) => fetch(`${REACT_APP_BACKEND_DOMAIN}/proxy/api/1.2/users/basicinfo?userId=${userId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then((resp) => {
  if (!resp.ok) throw resp;
  return resp.json();
});

export {
  getUser,
  getUserInfo,
};
