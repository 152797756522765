import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  FETCH_GROUPS: '@groups/FETCH_GROUPS',
  FETCH_GROUPS_REQUEST: '@groups/FETCH_GROUPS_REQUEST',
  FETCH_GROUPS_SUCCESS: '@groups/FETCH_GROUPS_SUCCESS',
  FETCH_GROUPS_FAILURE: '@groups/FETCH_GROUPS_FAILURE',
  CLEAR: '@groups/CLEAR',
  FETCH_GROUPS_USERS: '@groups/FETCH_GROUPS_USERS',
  FETCH_GROUPS_USERS_REQUEST: '@groups/FETCH_GROUPS_USERS_REQUEST',
  FETCH_GROUPS_USERS_SUCCESS: '@groups/FETCH_GROUPS_USERS_SUCCESS',
  FETCH_GROUPS_USERS_FAILURE: '@groups/FETCH_GROUPS_USERS_FAILURE',
  FETCH_GROUPS_USERS_DETAILS: '@groups/FETCH_GROUPS_USERS_DETAILS',
  REFETCH_GROUP: '@groups/REFETCH_GROUP',
  CREATE_GROUP: '@groups/CREATE_GROUPS',
  CREATE_GROUP_REQUEST: '@groups/CREATE_GROUP_REQUEST',
  CREATE_GROUP_SUCCESS: '@groups/CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAILURE: '@groups/CREATE_GROUP_FAILURE',
  UPDATE_GROUP: '@groups/UPDATE_GROUP',
  UPDATE_GROUP_REQUEST: '@groups/UPDATE_GROUP_REQUEST',
  UPDATE_GROUP_SUCCESS: '@groups/UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_FAILURE: '@groups/UPDATE_GROUP_FAILURE',
  FETCH_GROUPS_USERS_SHALLOW: '@groups/FETCH_GROUPS_USERS_SHALLOW',
  FETCH_GROUPS_USERS_SHALLOW_REQUEST: '@groups/FETCH_GROUPS_USERS_SHALLOW_REQUEST',
  FETCH_GROUPS_USERS_SHALLOW_SUCCESS: '@groups/FETCH_GROUPS_USERS_SHALLOW_SUCCESS',
  FETCH_GROUPS_USERS_SHALLOW_FAILURE: '@groups/FETCH_GROUPS_USERS_SHALLOW_FAILURE',
};

export const fetchGroups = makeActionCreator(actionTypes.FETCH_GROUPS);
export const fetchGroupsRequest = makeActionCreator(actionTypes.FETCH_GROUPS_REQUEST);
export const fetchGroupsSuccess = makeActionCreator(actionTypes.FETCH_GROUPS_SUCCESS, 'groups');
export const fetchGroupsFailure = makeActionCreator(actionTypes.FETCH_GROUPS_FAILURE, 'error');
export const clear = makeActionCreator(actionTypes.CLEAR);
export const fetchGroupsUsers = makeActionCreator(actionTypes.FETCH_GROUPS_USERS, 'groupId', 'page', 'sortBy', 'orderBy', 'onboarding', 'tos', 'zipcode');
export const fetchGroupsUsersRequest = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_REQUEST);
export const fetchGroupsUsersSuccess = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_SUCCESS, 'users', 'groupId');
export const fetchGroupsUsersFailure = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_FAILURE, 'error');
export const fetchGroupsUsersDetails = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_DETAILS, 'groupId');
export const refetchGroup = makeActionCreator(actionTypes.REFETCH_GROUP, 'groupId', 'page');
export const createGroup = makeActionCreator(actionTypes.CREATE_GROUP, 'name', 'tos');
export const createGroupRequest = makeActionCreator(actionTypes.CREATE_GROUP_REQUEST);
export const createGroupSuccess = makeActionCreator(actionTypes.CREATE_GROUP_SUCCESS, 'group');
export const createGroupFailure = makeActionCreator(actionTypes.CREATE_GROUP_FAILURE, 'error');
export const updateGroup = makeActionCreator(actionTypes.UPDATE_GROUP, 'groupId', 'name', 'tos');
export const updateGroupRequest = makeActionCreator(actionTypes.UPDATE_GROUP_REQUEST);
export const updateGroupSuccess = makeActionCreator(actionTypes.UPDATE_GROUP_SUCCESS, 'group', 'groupId');
export const updateGroupFailure = makeActionCreator(actionTypes.UPDATE_GROUP_FAILURE, 'error');
export const fetchGroupsUsersShallow = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_SHALLOW);
export const fetchGroupsUsersShallowRequest = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_SHALLOW_REQUEST);
export const fetchGroupsUsersShallowSuccess = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_SHALLOW_SUCCESS);
export const fetchGroupsUsersShallowFailure = makeActionCreator(actionTypes.FETCH_GROUPS_USERS_SHALLOW_FAILURE, 'error');
