const { REACT_APP_BACKEND_DOMAIN = '' } = process.env;

const getLogs = (fileName, token) => fetch(`${REACT_APP_BACKEND_DOMAIN}/oid/logs?fileName=${fileName}`, {
  headers: { Authorization: `Bearer ${token}` },
}).then((resp) => {
  if (!resp.ok) throw resp;
  return resp.json();
});

const uploadOID = (data, fileName, token) => fetch(`${REACT_APP_BACKEND_DOMAIN}/oid?fileName=${fileName}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  method: 'POST',
  body: data,
}).then((resp) => {
  if (!resp.ok) throw resp;
  return resp;
});

export { getLogs, uploadOID };
