import sjcl from 'sjcl';

const crypto = {
  encrypt(secret, data) {
    if (data && typeof data === 'object') {
      return sjcl.encrypt(secret, JSON.stringify(data));
    }
    return sjcl.encrypt(secret, data);
  },
  decrypt(secret, data) {
    const decrypted = sjcl.decrypt(secret, data);
    try {
      return JSON.parse(decrypted);
    } catch (e) {
      return decrypted;
    }
  },
};

export default crypto;
