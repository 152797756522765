import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Home, PrivateRoute } from './layouts';
import { Authentication, OAuth2Callback } from './containers';
import { OAuthActions } from './redux/actions';

class App extends Component {
  componentWillMount() {
    const { waitingForAuthentication } = this.props;

    waitingForAuthentication();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/auth" component={Authentication} />
          <Route exact path="/auth/:provider/callback" component={OAuth2Callback} />
          <PrivateRoute component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  waitingForAuthentication: PropTypes.func.isRequired,
};

export default connect(null, {
  waitingForAuthentication: OAuthActions.prepare,
})(App);
