import { actionTypes } from '../actions/oidUpload';

function OidUploadReducer(state = {}, action) {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.FETCH_LOGS_SUCCESS:
      return {
        ...state,
        [payload.fileName]: payload.logs,
      };
    case actionTypes.FETCH_LOGS_FAILURE:
      return {
        ...state,
        [payload.fileName]: payload.error,
      };
    default:
      return state;
  }
}

export default OidUploadReducer;

export const getLogs = state => state.oidUpload || {};
