import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Input, Table } from 'reactstrap';
import { SelectBox } from '../../shared/ui';
import * as UsersSelectors from '../../../redux/reducers/users';
import './UsersTable.css';

class TableFilter extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    const { value } = props;

    super(props);

    this.state = {
      value: value ? value : '',
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  onKeyPress(e) {
    const { onChange } = this.props;

    if (e.key === 'Enter') {
      if (e.target.value === '') {
        onChange(null);
      } else {
        onChange(e.target.value);
      }
    }
  }

  render() {
    const { value } = this.state;

    return <Input value={value} onChange={this.onChange} onKeyPress={this.onKeyPress} />;
  }
}

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.mapData = this.mapData.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSortedChange = this.handleSortedChange.bind(this);
    this.handleTdProps = this.handleTdProps.bind(this);
    this.renderOnboardingSteps = this.renderOnboardingSteps.bind(this);
  }

  mapData(data) {
    const { usersDetails } = this.props;

    return data.map(user => ({
      fullName: usersDetails[user.id] && usersDetails[user.id].enertalk ? usersDetails[user.id].enertalk.name : '',
      email: usersDetails[user.id] && usersDetails[user.id].enertalk ? usersDetails[user.id].enertalk.email : '',
      enertalkId: user.id,
      usagePointId: usersDetails[user.id] && usersDetails[user.id].adapter && usersDetails[user.id].adapter.usagePointId ? usersDetails[user.id].adapter.usagePointId : '',
      zipcode: user.zipcode,
      status: usersDetails[user.id] && usersDetails[user.id].integration ? usersDetails[user.id].integration.status : '',
      tos: usersDetails[user.id] && usersDetails[user.id].olivine && usersDetails[user.id].olivine.analytics ? usersDetails[user.id].olivine.analytics.tos : '',
      createdAt: usersDetails[user.id] && usersDetails[user.id].olivine && usersDetails[user.id].olivine.analytics ? usersDetails[user.id].olivine.analytics.createdAt : '',
      updatedAt: usersDetails[user.id] && usersDetails[user.id].olivine && usersDetails[user.id].olivine.analytics ? usersDetails[user.id].olivine.analytics.updatedAt : '',
      lastDataAt: usersDetails[user.id] && usersDetails[user.id].integration ? new Date(usersDetails[user.id].integration.lastDataTime).toISOString() : '',
      onboarding: usersDetails[user.id] && usersDetails[user.id].olivine && usersDetails[user.id].olivine.analytics ? usersDetails[user.id].olivine.analytics.onboarding : '',
      onboardingSteps: usersDetails[user.id] && usersDetails[user.id].olivine && usersDetails[user.id].olivine.analytics ? usersDetails[user.id].olivine.analytics.events : [],
      mobileVersion: usersDetails[user.id] && usersDetails[user.id].olivine && usersDetails[user.id].olivine.analytics ? usersDetails[user.id].olivine.analytics.mobileVersion : '',
    }));
  }

  getColumns() {
    const { filters, selected } = this.props;

    return [
      {
        Header: '',
        width: 45,
        Cell: ({ original: { enertalkId } }) => <SelectBox selected={selected.indexOf(enertalkId) > -1} key={enertalkId} />,
        id: 'selector',
        style: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'EnerTalk ID',
        accessor: 'enertalkId',
        maxWidth: 100,
        sortable: true,
      },
      {
        Header: 'Usage Point ID',
        accessor: 'usagePointId',
        maxWidth: 90,
      },
      {
        Header: 'Zipcode',
        accessor: 'zipcode',
        maxWidth: 65,
        sortable: true,
        filterable: true,
        Filter: ({ onChange }) => <TableFilter onChange={onChange} value={filters.zipcode} />,
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 70,
      },
      {
        Header: 'ToS',
        accessor: 'tos',
        maxWidth: 45,
        sortable: true,
        filterable: true,
        Filter: ({ onChange }) => <TableFilter onChange={onChange} value={filters.tos} />,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        sortable: true,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        sortable: true,
      },
      {
        Header: 'Last Data At',
        accessor: 'lastDataAt',
      },
      {
        Header: 'Onboarding',
        accessor: 'onboarding',
        maxWidth: 45,
        sortable: true,
        filterable: true,
        Filter: ({ onChange }) => <TableFilter onChange={onChange} value={filters.onboarding} />,
      },
      {
        expander: true,
        Header: 'Onboarding Steps',
        width: 65,
        Expander: ({ isExpanded }) => (
          <div>
            {isExpanded
              ? <span>&#x2299;</span>
              : <span>&#x2295;</span>}
          </div>
        ),
        style: {
          cursor: 'pointer',
          fontSize: 25,
          padding: 0,
          textAlign: 'center',
          userSelect: 'none',
        },
      },
      {
        Header: 'MobileVersion',
        accessor: 'mobileVersion',
      },
    ];
  }

  handleFilterChange(filtered) {
    const { onFilteredChange } = this.props;
    const params = {};

    for (let i = 0; i < filtered.length; i += 1) {
      params[filtered[i].id] = filtered[i].value !== undefined ? filtered[i].value : null;
    }

    onFilteredChange(params);
  }

  handleSortedChange(newSorted) {
    const { onSortedChange } = this.props;
    let sortBy, orderBy;

    if (newSorted[0]) {
      switch (newSorted[0].id) {
        case 'enertalkId':
          sortBy = 'id';
          break;
        case 'createdAt':
          sortBy = 'created_at';
          break;
        case 'updatedAt':
          sortBy = 'updated_at';
          break;
        default:
          sortBy = newSorted[0].id;
      }

      if (newSorted[0].desc) {
        orderBy = 'desc';
      } else {
        orderBy = 'asc';
      }
    }

    onSortedChange(sortBy, orderBy);
  }

  handleTdProps(state, rowInfo, column) {
    const { onSelectUser, onClickUser } = this.props;

    return {
      onClick: (e, handleOriginal) => {
        if (handleOriginal) {
          handleOriginal();
        }

        if (rowInfo && rowInfo.original && rowInfo.original.enertalkId) {
          if (column.id === 'selector') {
            onSelectUser(rowInfo.original.enertalkId);
          } else if (column.Header !== 'Onboarding Steps') {
            onClickUser(rowInfo.original.enertalkId);
          }
        }
      },
    };
  }

  renderOnboardingSteps(rowInfo) {
    return (
      <Table size="sm" className="my-0" striped>
        <thead>
          <tr>
            <th>
              Event
            </th>
            <th>
              Time  
            </th>
          </tr>
        </thead>
        <tbody>
          {rowInfo.original.onboardingSteps.map(step => (
            <tr key={`${step.name}${step.createdAt}`}>
              <td>
                {step.name}
              </td>
              <td>
                {new Date(step.createdAt).toLocaleString()}
              </td>
            </tr>
            ))}
        </tbody>
      </Table>
    );
  }

  render() {
    const {
      users,
      offset,
      totalCount,
      onPageChange,
      sortBy,
      orderBy,
      loading,
      className,
    } = this.props;

    return (
      <ReactTable
        className={`UsersTable${className ? ` ${className}` : ''}`}
        data={this.mapData(users)}
        columns={this.getColumns()}
        page={Math.floor(offset / 20)}
        pages={Math.ceil(totalCount / 20)}
        pageSize={20}
        loading={loading}
        onPageChange={onPageChange}
        onFilteredChange={this.handleFilterChange}
        onSortedChange={this.handleSortedChange}
        sorted={sortBy ? [
          {
            id: (() => {
              switch (sortBy) {
                case 'id':
                  return 'enertalkId';
                case 'created_at':
                  return 'createdAt';
                case 'updated_at':
                  return 'updatedAt';
                default:
                  return sortBy;
              }
            })(),
            desc: orderBy === 'desc',
          },
        ] : undefined}
        sortable={false}
        getTdProps={this.handleTdProps}
        SubComponent={this.renderOnboardingSteps}
        manual
      />
    );
  }
}

const mapStateToProps = state => ({
  usersDetails: UsersSelectors.getUsers(state),
});

export default connect(mapStateToProps)(UsersTable);
