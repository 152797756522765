import { all, fork, spawn } from 'redux-saga/effects';
import oauthSaga from './oauth';
import userSaga from './user';
import groupsSaga from './groups';
import usersSaga from './users';
import messagesSaga from './messages';
import drSaga from './dr';
import uiSaga from './ui';
import oidUploadSaga from './oidUpload';

function* rootSaga() {
  return yield all([
    spawn(oauthSaga),
    fork(userSaga),
    fork(groupsSaga),
    fork(usersSaga),
    fork(messagesSaga),
    fork(drSaga),
    fork(uiSaga),
    fork(oidUploadSaga),
  ]);
}

export default rootSaga;
