import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';
import { Button } from '../../ui';
import './Modal.css';

const ModalComponent = ({ toggle, isOpen, alert, alertColor, header, footerButtons, children, className }) => (
  <Modal isOpen={isOpen} toggle={toggle} className={`ModalComponent${className ? ` ${className}` : ''}`}>
    <ModalHeader toggle={toggle}>
      { header }
    </ModalHeader>
    <ModalBody>
      { alert ? (
        <Alert color={alertColor}>
          { alert }
        </Alert>) : undefined }
      { children }
    </ModalBody>
    <ModalFooter>
      { footerButtons.map(({ onClick, color, disabled, title }) => (
        <Button
          key={title}
          onClick={onClick}
          color={color || 'primary'}
          disabled={disabled}
        >
          {title}
        </Button>
      )) }
    </ModalFooter>
  </Modal>
);

ModalComponent.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  alert: PropTypes.string,
  alertColor: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  footerButtons: PropTypes.array,
};

ModalComponent.defaultProps = {
  footerButtons: [],
  alert: '',
  alertColor: 'primary',
  header: '',
  children: [],
  className: '',
};

export default ModalComponent;
