import {
  call,
  put,
  select,
  takeLatest,
  all,
  fork,
} from 'redux-saga/effects';
import api from '../api';
import { GroupsActions, UsersActions } from '../actions';
import * as GroupsSelectors from '../reducers/groups';
import * as UsersSelectors from '../reducers/users';
import { getTokens } from '../reducers/oauth';
import { toError } from '../../utils';

const { actionTypes } = GroupsActions;

function* fetchGroups() {
  try {
    yield put(GroupsActions.fetchGroupsRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.getGroups, accessToken);

    yield put(GroupsActions.fetchGroupsSuccess(response));
  } catch (err) {
    yield put(GroupsActions.fetchGroupsFailure(toError(err)));
  }
}

function* fetchGroupsUsersDetails(groupId, refetch = false) {
  const allGroupsUsers = yield select(GroupsSelectors.users);
  const allUsers = yield select(UsersSelectors.getUsers);

  const { users } = allGroupsUsers[groupId];

  for (let i = 0; i < users.length; i += 1) {
    const { id } = users[i];

    if (refetch || !allUsers[id]) {
      yield put(UsersActions.fetchUsersProfile(id));
    }
  }
}

function* fetchGroupsUsers(groupId = 'allUsers', page = 0, refetch = false, noDetails = false, sortBy, orderBy, onboarding, tos, zipcode) {
  const groups = yield select(GroupsSelectors.users);

  if (groups[groupId]) {
    if (sortBy === undefined) sortBy = groups[groupId].sortBy;
    if (sortBy === null) sortBy = undefined;
    if (orderBy === undefined) orderBy = groups[groupId].orderBy;
    if (orderBy === null) orderBy = undefined;
    if (onboarding === undefined) onboarding = groups[groupId].filters.onboarding;
    if (onboarding === null) onboarding = undefined;
    if (tos === undefined) tos = groups[groupId].filters.tos;
    if (tos === null) tos = undefined;
    if (zipcode === undefined) zipcode = groups[groupId].filters.zipcode;
    if (zipcode === null) zipcode = undefined;
  }

  try {
    yield put(GroupsActions.fetchGroupsUsersRequest());

    const { accessToken } = yield select(getTokens);

    let response;

    if (groupId === 'allUsers') {
      response = yield call(api.olivineDr.getAllUsers, accessToken, undefined, page > 0 ? page * 20 : 0, sortBy, orderBy, onboarding, tos, zipcode);
    } else {
      response = yield call(api.olivineDr.getGroupsUsers, groupId, accessToken, undefined, page > 0 ? page * 20 : 0, sortBy, orderBy, onboarding, tos, zipcode);
    }

    yield put(GroupsActions.fetchGroupsUsersSuccess(response, groupId));

    if (!noDetails) yield fork(fetchGroupsUsersDetails, groupId, refetch);
  } catch (err) {
    yield put(GroupsActions.fetchGroupsUsersFailure(toError(err)));
  }
}

function* refetchGroup(groupId, page) {
  yield call(fetchGroups);
  yield call(fetchGroupsUsers, groupId, page, true);
}

function* createGroup(name, tos) {
  try {
    yield put(GroupsActions.createGroupRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.createGroup, name, tos, accessToken);

    yield put(GroupsActions.createGroupSuccess(response));
  } catch (err) {
    yield put(GroupsActions.createGroupFailure(toError(err)));
  }
}

function* updateGroup(groupId, name, tos) {
  try {
    yield put(GroupsActions.updateGroupRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.updateGroup, groupId, name, tos, accessToken);

    yield put(GroupsActions.updateGroupSuccess(response, groupId));
  } catch (err) {
    yield put(GroupsActions.updateGroupFailure(toError(err)));
  }
}

function* fetchGroupsUsersShallow() {
  try {
    yield put(GroupsActions.fetchGroupsUsersShallowRequest());

    yield call(fetchGroups);

    const groups = yield select(GroupsSelectors.groups);

    yield all(groups.map(group => fork(fetchGroupsUsers, group.id, undefined, undefined, true)));

    yield put(GroupsActions.fetchGroupsUsersShallowSuccess());
  } catch (err) {
    yield put(GroupsActions.fetchGroupsUsersShallowFailure(toError(err)));
  }
}

function* performByAction(action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_GROUPS:
      return yield call(fetchGroups);
    case actionTypes.FETCH_GROUPS_USERS:
      return yield call(fetchGroupsUsers, payload.groupId, payload.page, undefined, undefined, payload.sortBy, payload.orderBy, payload.onboarding, payload.tos, payload.zipcode);
    case actionTypes.REFETCH_GROUP:
      return yield call(refetchGroup, payload.groupId, payload.page);
    case actionTypes.CREATE_GROUP:
      return yield call(createGroup, payload.name, payload.tos);
    case actionTypes.UPDATE_GROUP:
      return yield call(updateGroup, payload.groupId, payload.name, payload.tos);
    case actionTypes.FETCH_GROUPS_USERS_SHALLOW:
      return yield call(fetchGroupsUsersShallow);
    default:
      return null;
  }
}

function* groupsSaga() {
  yield all([
    takeLatest([
      actionTypes.FETCH_GROUPS,
    ], performByAction),
    takeLatest([
      actionTypes.FETCH_GROUPS_USERS,
    ], performByAction),
    takeLatest([
      actionTypes.REFETCH_GROUP,
    ], performByAction),
    takeLatest([
      actionTypes.CREATE_GROUP,
    ], performByAction),
    takeLatest([
      actionTypes.UPDATE_GROUP,
    ], performByAction),
    takeLatest([
      actionTypes.FETCH_GROUPS_USERS_SHALLOW,
    ], performByAction),
  ]);
}

export default groupsSaga;

export { fetchGroupsUsers };
