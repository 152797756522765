import React, { Component } from 'react';
import { GroupsList } from '../../components';
import GroupsDrEvents from '../../containers/GroupsDrEvents';
import './DrEventsList.css';

class DrEventsList extends Component {
  selectGroup = (groupId) => {
    const { history } = this.props;

    return history.push(`/dr/groups/${groupId}`);
  };

  selectEvent = (eventId) => {
    const { history } = this.props;

    return history.push(`/dr/events/${eventId}`);
  }

  render() {
    const {
      match: {
        params: { groupId },
      },
    } = this.props;

    return (
      <div className="DrEventsList">
        <div className="GroupsList">
          <GroupsList clickHandle={this.selectGroup} selectedGroup={groupId} />
        </div>
        { groupId !== undefined ? (
          <div className="DREventsList">
            <GroupsDrEvents groupId={groupId} onClick={this.selectEvent}/>
          </div>
        ) : (
          undefined
        )}
      </div>
    )
  }
}

export default DrEventsList;
