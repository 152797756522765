import {
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  OAuthActions,
  UserActions,
  UsersActions,
  GroupsActions,
  DrActions,
  MessagesActions,
  OidUploadActions,
} from '../actions';
import * as OAuthSelectors from '../reducers/oauth';
import api from '../api';
import { crypto, generateRandomHash } from '../helpers';
import { toError } from '../../utils';
import { store } from '../../store';

const { actionTypes } = OAuthActions;

function getOAuthEndpoint(needSignUp, nonce) {
  const domain = process.env.REACT_APP_AUTH_DOMAIN;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
  const path = needSignUp ? 'signup' : 'authorization';

  return `${domain}/${path}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}&state=${nonce}`;
}

function moveToAuthServer(endpoint) {
  window.location.href = endpoint;
}

function* obtainToken(code) {
  yield put(OAuthActions.exchangeTokenRequest());
  const tokens = yield call(api.oauth.exchangeToken, code);
  return yield tokens;
}

function* signInFlow(needSignUp = false) {
  const nonce = generateRandomHash();
  yield put(OAuthActions.saveNonce(nonce));

  const endpoint = getOAuthEndpoint(needSignUp, nonce);
  yield call(moveToAuthServer, endpoint);
}

function updateAuthConfig(tokens) {
  const authConfig = {
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
    tokenUpdateFn(newTokens) {
      const encryptedToken = crypto.encrypt(process.env.REACT_APP_CRYPTO_SECRET, newTokens || '');
      // Call dispatch directly because it can't use saga effects in callback
      store.dispatch(OAuthActions.updateToken(encryptedToken));
    },
  };

  return authConfig;
}

function* clearResources() {
  yield put(UserActions.clear());
  yield put(UsersActions.clear());
  yield put(GroupsActions.clear());
  yield put(DrActions.clear());
  yield put(MessagesActions.clear());
  yield put(OidUploadActions.clear());
}

function* tokenExchangeFlow(returnedNonce, authorizationCode) {
  const storedNonce = yield select(OAuthSelectors.getNonce);

  if (storedNonce !== returnedNonce) {
    const error = toError('state(nonce) does not matching');
    return yield put(OAuthActions.exchangeTokenFailure(error));
  }

  try {
    const tokens = yield call(obtainToken, authorizationCode);
    yield call(updateAuthConfig, tokens);

    const encryptedToken = crypto.encrypt(process.env.REACT_APP_CRYPTO_SECRET, tokens || '');
    yield put(OAuthActions.exchangeTokenSuccess(encryptedToken));
    yield put(OAuthActions.markAsAuthenticated());
    yield call(clearResources);
    return yield put(OAuthActions.ready());
  } catch (error) {
    return yield put(OAuthActions.exchangeTokenFailure(toError(error)));
  }
}

function* signOutFlow() {
  yield call(clearResources);
  yield put(OAuthActions.clear());
}

function* performAuthentication(action) {
  const { type, payload } = action;
  const needSignUp = type === actionTypes.SIGNUP;

  switch (type) {
    case actionTypes.SIGNUP:
    case actionTypes.SIGNIN:
      return yield call(signInFlow, needSignUp);
    case actionTypes.EXCHANGE_TOKEN:
      return yield call(tokenExchangeFlow, payload.nonce, payload.code);
    case actionTypes.SIGNOUT:
      return yield call(signOutFlow);
    default:
      return null;
  }
}

function* watchAuthentication() {
  yield takeEvery([
    actionTypes.SIGNIN,
    actionTypes.SIGNUP,
    actionTypes.EXCHANGE_TOKEN,
    actionTypes.SIGNOUT,
  ], performAuthentication);
}

function* prepareAuthentication() {
  try {
    const isAuthenticated = yield select(OAuthSelectors.authenticated);

    if (isAuthenticated) {
      const existingTokens = yield select(OAuthSelectors.getTokens);
      yield call(updateAuthConfig, existingTokens);
      yield put(OAuthActions.ready());
    }
  } catch (error) {
    yield call(signOutFlow);
  }
}

function* oauthSaga() {
  yield all([
    takeLatest(actionTypes.PREPARE, prepareAuthentication),
    fork(watchAuthentication),
  ]);
}

export default oauthSaga;
export {
  watchAuthentication,
  prepareAuthentication,
  performAuthentication,
  signInFlow,
  signOutFlow,
  tokenExchangeFlow,
  obtainToken,
  moveToAuthServer,
  updateAuthConfig,
  clearResources,
};
