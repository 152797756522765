const { REACT_APP_BACKEND_DOMAIN = '' } = process.env;

const getAdapterUser = (userId, token) => fetch(`${REACT_APP_BACKEND_DOMAIN}/proxy/adapter/users/${userId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then((resp) => {
  if (!resp.ok) throw resp;
  return resp.json();
});

const getIntegration = (siteId, token) => fetch(`${REACT_APP_BACKEND_DOMAIN}/proxy/adapter/integrations?siteId=${siteId}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then((resp) => {
  if (!resp.ok) throw resp;
  return resp.json();
});

export { getAdapterUser, getIntegration };
