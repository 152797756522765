import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  FETCH_OLIVINE_USER_PROFILE: '@user/FETCH_OLIVINE_USER_PROFILE',
  FETCH_OLIVINE_USER_PROFILE_REQUEST: '@user/FETCH_OLIVINE_USER_PROFILE_REQUEST',
  FETCH_OLIVINE_USER_PROFILE_SUCCESS: '@user/FETCH_OLIVINE_USER_PROFILE_SUCCESS',
  FETCH_OLIVINE_USER_PROFILE_FAILURE: '@user/FETCH_OLIVINE_USER_PROFILE_FAILURE',
  FETCH_ENERTALK_USER_PROFILE: '@user/FETCH_ENERTALK_USER_PROFILE',
  FETCH_ENERTALK_USER_PROFILE_REQUEST: '@user/FETCH_ENERTALK_USER_PROFILE_REQUEST',
  FETCH_ENERTALK_USER_PROFILE_SUCCESS: '@user/FETCH_ENERTALK_USER_PROFILE_SUCCESS',
  FETCH_ENERTALK_USER_PROFILE_FAILURE: '@user/FETCH_ENERTALK_USER_PROFILE_FAILURE',
  FETCH_USER_PROFILE: '@user/FETCH_USER_PROFILE',
  CLEAR: '@user/CLEAR',
};

export const fetchOlivineUserProfile = makeActionCreator(actionTypes.FETCH_OLIVINE_USER_PROFILE);
export const fetchOlivineUserProfileRequest = makeActionCreator(actionTypes.FETCH_OLIVINE_USER_PROFILE_REQUEST);
export const fetchOlivineUserProfileSuccess = makeActionCreator(actionTypes.FETCH_OLIVINE_USER_PROFILE_SUCCESS, 'user');
export const fetchOlivineUserProfileFailure = makeActionCreator(actionTypes.FETCH_OLIVINE_USER_PROFILE_FAILURE, 'error');
export const fetchEnertalkUserProfile = makeActionCreator(actionTypes.FETCH_ENERTALK_USER_PROFILE);
export const fetchEnertalkUserProfileRequest = makeActionCreator(actionTypes.FETCH_ENERTALK_USER_PROFILE_REQUEST);
export const fetchEnertalkUserProfileSuccess = makeActionCreator(actionTypes.FETCH_ENERTALK_USER_PROFILE_SUCCESS, 'user');
export const fetchEnertalkUserProfileFailure = makeActionCreator(actionTypes.FETCH_ENERTALK_USER_PROFILE_FAILURE, 'error');
export const fetchUserProfile = makeActionCreator(actionTypes.FETCH_USER_PROFILE);
export const clear = makeActionCreator(actionTypes.CLEAR);
