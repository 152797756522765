import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  Router,
} from 'react-router-dom';
import { GroupsActions } from '../../redux/actions';
import * as GroupsSelectors from '../../redux/reducers/groups';
import { GroupsUserlist, GroupsHome } from '../../containers';
import './Groups.css';

class Groups extends Component {
  constructor(props) {
    super(props);

    this.selectGroup = this.selectGroup.bind(this);
  }

  componentDidMount() {
    const { groups, isLoading, fetchGroups } = this.props;

    if (!isLoading && groups.length < 1) {
      fetchGroups();
    }
  }

  selectGroup(groupId) {
    const { history } = this.props;

    return history.push(`/groups/${groupId}`);
  }

  render() {
    const { history } = this.props;

    return (
      <div className="Groups">
        <Router history={history}>
          <Switch>
            <Route exact path="/groups" component={GroupsHome} />
            <Route path="/groups/:groupId" component={GroupsUserlist} />
          </Switch>
        </Router>
      </div>
    );
  }
}

Groups.propTypes = {
  isLoading: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    tos: PropTypes.string,
  })),
  fetchGroups: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

Groups.defaultProps = {
  isLoading: undefined,
  groups: [],
};

const mapStateToProps = state => ({
  groups: GroupsSelectors.groups(state),
  isLoading: GroupsSelectors.isLoading(state),
});

export default connect(mapStateToProps, {
  fetchGroups: GroupsActions.fetchGroups,
})(Groups);
