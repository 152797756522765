import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Toolbar, Tabs } from '../../components/shared/ui';
import { MessagesActions } from '../../redux/actions';
import * as MessagesSelectors from '../../redux/reducers/messages';
import { MessagesTable } from '../../components';
import { NewMessageModal } from '../../components/Messages/modals';
import './MessagesHome.css';

class MessagesHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabOpen: 0,
      showNewMessageModal: false,
    };

    this.toggleNewMessageModal = this.toggleNewMessageModal.bind(this);
    this.selectMessage = this.selectMessage.bind(this);
  }

  componentDidMount() {
    const {
      pastMessages,
      upcomingMessages,
      fetchMessages,
      loading,
    } = this.props;

    if ((!pastMessages || !upcomingMessages) && !loading) fetchMessages();
  }

  toggleNewMessageModal(bool) {
    if (typeof bool === 'boolean') {
      return this.setState({
        showNewMessageModal: bool,
      });
    }

    const { showNewMessageModal } = this.state;

    return this.setState({
      showNewMessageModal: !showNewMessageModal,
    });
  }

  selectMessage(messageId) {
    const { history } = this.props;

    return history.push(`/messages/${messageId}`);
  }

  render() {
    const { tabOpen, showNewMessageModal } = this.state;
    const {
      pastMessages,
      upcomingMessages,
      fetchMessages,
      loading,
      fetchNextPage,
      fetchPrevPage,
      pastPage,
      pastTotalPages,
      upcomingPage,
      upcomingTotalPages,
    } = this.props;

    return (
      <div className="MessagesHome">
        <Toolbar
          className="MessagesHome__Toolbar"
          left={(
            <Tabs
              tabs={['Upcoming', 'Past']}
              onChange={tab => this.setState({ tabOpen: tab })}
              selectedTab={tabOpen}
              className="TabPills"
            />
          )}
          buttons={[
            {
              name: 'Prev',
              onClick: () => fetchPrevPage('past'),
              disabled: (tabOpen ? pastPage <= 1 : upcomingPage <= 1) || loading,
            },
            (
              <div className="page-counter" key="page-counter">
                { tabOpen ? `${pastTotalPages ? pastPage : 0} / ${pastTotalPages}` : `${upcomingTotalPages ? upcomingPage : 0} / ${upcomingTotalPages}` }
              </div>
            ),
            {
              name: 'Next',
              onClick: () => fetchNextPage('past'),
              disabled: (tabOpen ? (pastPage === pastTotalPages || !pastTotalPages) : (upcomingPage === upcomingTotalPages || !upcomingTotalPages)) || loading,
            },
            {
              name: 'Refresh',
              onClick: () => fetchMessages(tabOpen ? 'past' : 'upcoming'),
              disabled: loading,
            },
            {
              name: 'New',
              onClick: () => this.toggleNewMessageModal(true),
            },
          ]}
        />
        <MessagesTable
          messages={tabOpen ? pastMessages : upcomingMessages}
          onClick={this.selectMessage}
        />

        <NewMessageModal
          isOpen={showNewMessageModal}
          toggle={this.toggleNewMessageModal}
        />
      </div>
    );
  }
}

MessagesHome.propTypes = {
  fetchMessages: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pastMessages: PropTypes.array.isRequired,
  upcomingMessages: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  fetchPrevPage: PropTypes.func.isRequired,
  pastPage: PropTypes.number.isRequired,
  pastTotalPages: PropTypes.number.isRequired,
  upcomingPage: PropTypes.number.isRequired,
  upcomingTotalPages: PropTypes.number.isRequired,
};

MessagesHome.defaultProps = {
  loading: undefined,
};

const mapStateToProps = state => ({
  loading: MessagesSelectors.isLoading(state),
  pastMessages: MessagesSelectors.pastMessages(state),
  upcomingMessages: MessagesSelectors.upcomingMessages(state),
  pastPage: MessagesSelectors.getPastPage(state),
  pastTotalPages: MessagesSelectors.getPastTotalPages(state),
  upcomingPage: MessagesSelectors.getUpcomingPage(state),
  upcomingTotalPages: MessagesSelectors.getUpcomingTotalPages(state),
});

export default connect(mapStateToProps, {
  fetchMessages: MessagesActions.fetchMessages,
  fetchNextPage: MessagesActions.fetchMessagesNextPage,
  fetchPrevPage: MessagesActions.fetchMessagesPrevPage,
})(MessagesHome);
