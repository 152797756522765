import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UsersActions } from '../../../redux/actions';
import * as UsersSelectors from '../../../redux/reducers/users';
import { UserProfile, UserGroups } from '../../../components';
import './UsersDetails.css';

class UsersDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: undefined,
    };

    this.loadUser = this.loadUser.bind(this);
  }

  componentDidMount() {
    this.loadUser();
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { userId } } } = this.props;

    if (userId !== prevProps.match.params.userId) {
      this.loadUser();
    }
  }

  loadUser() {
    const { users, match: { params: { userId } }, fetchUsersProfile, isLoading } = this.props;

    this.setState({ selectedUser: Number(userId) });

    if (!users[userId] && !isLoading) {
      fetchUsersProfile(userId);
    }
  }

  render() {
    const { selectedUser } = this.state;

    return (
      <div className="UsersDetails">
        <UserProfile userId={selectedUser} />
        <UserGroups userId={selectedUser} />
      </div>
    );
  }
}

UsersDetails.propTypes = {
  users: PropTypes.object,
  fetchUsersProfile: PropTypes.func.isRequired,
};

UsersDetails.defaultProps = {
  users: {},
};

const mapStateToProps = state => ({
  users: UsersSelectors.getUsers(state),
  isLoading: UsersSelectors.isLoading(state),
});

export default connect(mapStateToProps, {
  fetchUsersProfile: UsersActions.fetchUsersProfile,
})(UsersDetails);
