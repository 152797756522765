import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Loading } from '../../components';
import { SimpleDataTable, UserLink } from '../../components/shared/ui';
import { MessagesActions } from '../../redux/actions';
import * as MessagesSelectors from '../../redux/reducers/messages';
import './MessagesDetailed.css';

class MessagesDetailed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMessage: props.match.params.messageId,
    };

    this.renderMessageContent = this.renderMessageContent.bind(this);
    this.renderMessageUsers = this.renderMessageUsers.bind(this);
    this.renderMessageStatus = this.renderMessageStatus.bind(this);
  }

  componentDidMount() {
    const { fetchMessage, fetchStatus } = this.props;
    const { selectedMessage } = this.state;

    fetchMessage(selectedMessage);
    fetchStatus(selectedMessage);
  }

  renderMessageContent() {
    const { selectedMessage } = this.state;
    const { messages } = this.props;

    const currentMessage = messages[selectedMessage];

    return (
      <SimpleDataTable
        header="Message Content"
        className="MessageContent"
        data={[
          {
            title: 'Message ID',
            content: selectedMessage,
          },
          {
            title: 'Title',
            content: currentMessage.message.title,
          },
          {
            title: 'Body',
            content: currentMessage.message.body,
          },
          {
            title: 'Created At',
            content: new Date(currentMessage.status.createdAt).toLocaleString(),
          },
          {
            title: 'Scheduled Send Time',
            content: new Date(currentMessage.status.scheduledAt).toLocaleString(),
          },
          {
            title: 'Send to Timeline',
            content: currentMessage.destination.timeline === true ? 'True' : 'False',
          },
          {
            title: 'Send as Notification',
            content: currentMessage.destination.notification === true ? 'True' : 'False',
          },
        ]}
      />
    );
  }

  renderMessageStatus() {
    const { selectedMessage } = this.state;
    const { messages } = this.props;

    const currentMessage = messages[selectedMessage];
    const hasBeenSent = currentMessage.status.attempted === true;

    return (
      <SimpleDataTable
        header="Message Status"
        className="Message Status"
        data={(() => {
          const data = [
            {
              title: 'Send Attempted',
              content: hasBeenSent ? 'True' : 'False',
            },
          ];

          if (hasBeenSent) {
            data.push({
              title: 'Attempted At',
              content: new Date(currentMessage.status.attemptAt).toLocaleString(),
            });
          }

          if (currentMessage.destination.groupId !== undefined) {
            data.push({
              title: 'Group Targeted',
              content: currentMessage.destination.groupId,
            });
          } else {
            data.push({
              title: 'Users Targeted',
              content: currentMessage.destination.users.join(', '),
            });
          }

          return data;
        })()}
      />
    );
  }

  renderMessageUsers() {
    const { selectedMessage } = this.state;
    const { messages, statuses } = this.props;

    const currentStatus = statuses[selectedMessage];
    const currentMessage = messages[selectedMessage];

    const hasBeenSent = currentMessage.status.attempted === true;

    return (
      <div className="MessageUsers">
        <h3>
          Users Statuses
        </h3>
        <Table striped>
          <thead>
            <tr>
              <th>
                User ID
              </th>
              <th>
                Timeline Status
              </th>
              <th>
                Notification Status
              </th>
            </tr>
          </thead>
          <tbody>
            { currentStatus.statuses.map(status => (
              <tr key={status.user}>
                <td>
                  <UserLink userId={status.user} />
                </td>
                { hasBeenSent && currentMessage.destination.timeline ? (
                  <td>
                    { status.timelineSuccess === true ? 'Sent' : `Error, ${status.timelineError}` }
                  </td>
                ) : undefined }
                { hasBeenSent && currentMessage.destination.notification ? (
                  <td>
                    { status.notificationSuccess === true ? 'Sent' : `Error, ${status.notificationError}` }
                  </td>
                ) : undefined }
              </tr>
            )) }
          </tbody>
        </Table>
      </div>
    )
  }

  render() {
    const { selectedMessage } = this.state;
    const { messages, statuses } = this.props;

    const currentMessage = messages[selectedMessage];
    const currentStatus = statuses[selectedMessage];

    if (!currentMessage || !currentStatus) return <Loading />;

    return (
      <div className="MessagesDetailed">
        { this.renderMessageContent() }
        { this.renderMessageStatus() }
        { this.renderMessageUsers() }
      </div>
    );
  }
}

MessagesDetailed.propTypes = {
  messages: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  fetchMessage: PropTypes.func.isRequired,
  fetchStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  messages: MessagesSelectors.messages(state),
  statuses: MessagesSelectors.statuses(state),
});

export default connect(mapStateToProps, {
  fetchMessage: MessagesActions.fetchMessage,
  fetchStatus: MessagesActions.fetchMessageStatus,
})(MessagesDetailed);
