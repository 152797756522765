import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  FETCH_USERS_ENERTALK_PROFILE: '@users/FETCH_USERS_ENERTALK_PROFILE',
  FETCH_USERS_ENERTALK_PROFILE_REQUEST: '@users/FETCH_USERS_ENERTALK_PROFILE_REQUEST',
  FETCH_USERS_ENERTALK_PROFILE_SUCCESS: '@users/FETCH_USERS_ENERTALK_PROFILE_SUCCESS',
  FETCH_USERS_ENERTALK_PROFILE_FAILURE: '@users/FETCH_USERS_ENERTALK_PROFILE_FAILURE',
  FETCH_USERS_OLIVINE_PROFILE: '@users/FETCH_USERS_OLIVINE_PROFILE',
  FETCH_USERS_OLIVINE_PROFILE_REQUEST: '@users/FETCH_USERS_OLIVINE_PROFILE_REQUEST',
  FETCH_USERS_OLIVINE_PROFILE_SUCCESS: '@users/FETCH_USERS_OLIVINE_PROFILE_SUCCESS',
  FETCH_USERS_OLIVINE_PROFILE_FAILURE: '@users/FETCH_USERS_OLIVINE_PROFILE_FAILURE',
  FETCH_USERS_ADAPTER_PROFILE: '@users/FETCH_USERS_ADAPTER_PROFILE',
  FETCH_USERS_ADAPTER_PROFILE_REQUEST: '@users/FETCH_USERS_ADAPTER_PROFILE_REQUEST',
  FETCH_USERS_ADAPTER_PROFILE_SUCCESS: '@users/FETCH_USERS_ADAPTER_PROFILE_SUCCESS',
  FETCH_USERS_ADAPTER_PROFILE_FAILURE: '@users/FETCH_USERS_ADAPTER_PROFILE_FAILURE',
  FETCH_USERS_PROFILE: '@users/FETCH_USERS_PROFILE',
  CLEAR: '@users/CLEAR',
  ADD_USER_TO_GROUP: '@users/ADD_USER_TO_GROUP',
  ADD_USER_TO_GROUP_REQUEST: '@users/ADD_USER_TO_GROUP_REQUEST',
  ADD_USER_TO_GROUP_SUCCESS: '@users/ADD_USER_TO_GROUP_SUCCESS',
  ADD_USER_TO_GROUP_FAILURE: '@users/ADD_USER_TO_GROUP_FAILURE',
  REMOVE_USER_FROM_GROUP: '@users/REMOVE_USER_FROM_GROUP',
  REMOVE_USER_FROM_GROUP_REQUEST: '@users/REMOVE_USER_FROM_GROUP_REQUEST',
  REMOVE_USER_FROM_GROUP_SUCCESS: '@users/REMOVE_USER_FROM_GROUP_SUCCESS',
  REMOVE_USER_FROM_GROUP_FAILURE: '@users/REMOVE_USER_FROM_GROUP_FAILURE',
  FETCH_USERS_INTEGRATION: '@users/FETCH_USERS_INTEGRATION',
  FETCH_USERS_INTEGRATION_REQUEST: '@users/FETCH_USERS_INTEGRATION_REQUEST',
  FETCH_USERS_INTEGRATION_SUCCESS: '@users/FETCH_USERS_INTEGRATION_SUCCESS',
  FETCH_USERS_INTEGRATION_FAILURE: '@users/FETCH_USERS_INTEGRATION_FAILURE',
  ADD_USERS_TO_GROUP: '@users/ADD_USERS_TO_GROUP',
  ADD_USERS_TO_GROUP_SUCCESS: '@users/ADD_USERS_TO_GROUP_SUCCESS',
  REMOVE_USERS_FROM_GROUP: '@users/REMOVE_USERS_FROM_GROUP',
  REMOVE_USERS_FROM_GROUP_SUCCESS: '@users/REMOVE_USERS_FROM_GROUP_SUCCESS',
};

export const fetchUsersEnertalkProfile = makeActionCreator(actionTypes.FETCH_USERS_ENERTALK_PROFILE, 'userId');
export const fetchUsersEnertalkProfileRequest = makeActionCreator(actionTypes.FETCH_USERS_ENERTALK_PROFILE_REQUEST);
export const fetchUsersEnertalkProfileSuccess = makeActionCreator(actionTypes.FETCH_USERS_ENERTALK_PROFILE_SUCCESS, 'user');
export const fetchUsersEnertalkProfileFailure = makeActionCreator(actionTypes.FETCH_USERS_ENERTALK_PROFILE_FAILURE, 'error');
export const fetchUsersOlivineProfile = makeActionCreator(actionTypes.FETCH_USERS_OLIVINE_PROFILE, 'userId');
export const fetchUsersOlivineProfileRequest = makeActionCreator(actionTypes.FETCH_USERS_OLIVINE_PROFILE_REQUEST);
export const fetchUsersOlivineProfileSuccess = makeActionCreator(actionTypes.FETCH_USERS_OLIVINE_PROFILE_SUCCESS, 'user');
export const fetchUsersOlivineProfileFailure = makeActionCreator(actionTypes.FETCH_USERS_OLIVINE_PROFILE_FAILURE, 'error');
export const fetchUsersAdapterProfile = makeActionCreator(actionTypes.FETCH_USERS_ADAPTER_PROFILE, 'userId');
export const fetchUsersAdapterProfileRequest = makeActionCreator(actionTypes.FETCH_USERS_ADAPTER_PROFILE_REQUEST);
export const fetchUsersAdapterProfileSuccess = makeActionCreator(actionTypes.FETCH_USERS_ADAPTER_PROFILE_SUCCESS, 'adapter');
export const fetchUsersAdapterProfileFailure = makeActionCreator(actionTypes.FETCH_USERS_ADAPTER_PROFILE_FAILURE, 'error');
export const fetchUsersProfile = makeActionCreator(actionTypes.FETCH_USERS_PROFILE, 'userId');
export const addUserToGroup = makeActionCreator(actionTypes.ADD_USER_TO_GROUP, 'userId', 'groupId');
export const addUserToGroupRequest = makeActionCreator(actionTypes.ADD_USER_TO_GROUP_REQUEST);
export const addUserToGroupSuccess = makeActionCreator(actionTypes.ADD_USER_TO_GROUP_SUCCESS);
export const addUserToGroupFailure = makeActionCreator(actionTypes.ADD_USER_TO_GROUP_FAILURE, 'error');
export const removeUserFromGroup = makeActionCreator(actionTypes.REMOVE_USER_FROM_GROUP, 'userId', 'groupId');
export const removeUserFromGroupRequest = makeActionCreator(actionTypes.REMOVE_USER_FROM_GROUP_REQUEST);
export const removeUserFromGroupSuccess = makeActionCreator(actionTypes.REMOVE_USER_FROM_GROUP_SUCCESS);
export const removeUserFromGroupFailure = makeActionCreator(actionTypes.REMOVE_USER_FROM_GROUP_FAILURE);
export const clear = makeActionCreator(actionTypes.CLEAR);
export const fetchUsersIntegration = makeActionCreator(actionTypes.FETCH_USERS_INTEGRATION, 'userId', 'siteId');
export const fetchUsersIntegrationRequest = makeActionCreator(actionTypes.FETCH_USERS_INTEGRATION_REQUEST);
export const fetchUsersIntegrationSuccess = makeActionCreator(actionTypes.FETCH_USERS_INTEGRATION_SUCCESS, 'integration');
export const fetchUsersIntegrationFailure = makeActionCreator(actionTypes.FETCH_USERS_INTEGRATION_FAILURE, 'error');
export const addUsersToGroup = makeActionCreator(actionTypes.ADD_USERS_TO_GROUP, 'users', 'groupId');
export const addUsersToGroupSuccess = makeActionCreator(actionTypes.ADD_USERS_TO_GROUP_SUCCESS);
export const removeUsersFromGroup = makeActionCreator(actionTypes.REMOVE_USERS_FROM_GROUP, 'users', 'groupId');
export const removeUsersFromGroupSuccess = makeActionCreator(actionTypes.REMOVE_USERS_FROM_GROUP_SUCCESS);
