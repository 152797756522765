import { call, put, select, takeEvery, fork } from 'redux-saga/effects';
import api from '../api';
import { fetchGroupsUsers } from './groups';
import { UsersActions } from '../actions';
import { getTokens } from '../reducers/oauth';
import { getUsers } from '../reducers/users';
import { toError } from '../../utils';

const { actionTypes } = UsersActions;

function* fetchUsersEnertalkProfile(userId) {
  try {
    yield put(UsersActions.fetchUsersEnertalkProfileRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.enertalk.getUserInfo, userId, accessToken);

    yield put(UsersActions.fetchUsersEnertalkProfileSuccess(response));
  } catch (err) {
    yield put(UsersActions.fetchUsersEnertalkProfileFailure(toError(err)));
  }
}

function* fetchUsersOlivineProfile(userId) {
  try {
    yield put(UsersActions.fetchUsersOlivineProfileRequest());

    const { accessToken } = yield select(getTokens);

    const profile = yield call(api.olivineDr.getUser, userId, accessToken);
    const analytics = yield call(api.olivineDr.getUserAnalytics, userId, accessToken);

    yield put(UsersActions.fetchUsersOlivineProfileSuccess({ profile, analytics }));
  } catch (err) {
    yield put(UsersActions.fetchUsersOlivineProfileFailure(toError(err)));
  }
}

function* fetchUsersAdapterProfile(userId) {
  try {
    yield put(UsersActions.fetchUsersAdapterProfileRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.amiAdapter.getAdapterUser, userId, accessToken);

    if (!response.length) {
      throw Error('no adapter profile found');
    }
    yield put(UsersActions.fetchUsersAdapterProfileSuccess(response[response.length - 1]));
  } catch (err) {
    yield put(UsersActions.fetchUsersAdapterProfileFailure(toError(err)));
  }
}

function* fetchUsersIntegration(userId) {
  try {
    yield put(UsersActions.fetchUsersIntegrationRequest());

    const { accessToken } = yield select(getTokens);
    const users = yield select(getUsers);

    const { siteId } = users[userId].adapter;

    const response = yield call(api.amiAdapter.getIntegration, siteId, accessToken);
    if (!response[0]) {
      throw Error('no integration found');
    }

    yield put(UsersActions.fetchUsersIntegrationSuccess(response[0]));
  } catch (err) {
    yield put(UsersActions.fetchUsersIntegrationFailure(toError(err)));
  }
}

function* fetchUsersProfile(userId) {
  yield fork(fetchUsersEnertalkProfile, userId);
  yield fork(fetchUsersOlivineProfile, userId);
  yield call(fetchUsersAdapterProfile, userId);
  yield call(fetchUsersIntegration, userId);
}

function* addUserToGroup(userId, groupId) {
  try {
    yield put(UsersActions.addUserToGroupRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.addToGroup, userId, groupId, accessToken);

    if (response.ok) {
      yield put(UsersActions.addUserToGroupSuccess());

      yield fork(fetchUsersProfile, userId);
      yield fork(fetchGroupsUsers, groupId);
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    yield put(UsersActions.addUserToGroupFailure(toError(err)));
  }
}

function* removeUserFromGroup(userId, groupId) {
  try {
    yield put(UsersActions.removeUserFromGroupRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.removeFromGroup, userId, groupId, accessToken);

    if (response.ok) {
      yield put(UsersActions.removeUserFromGroupSuccess());

      yield fork(fetchUsersProfile, userId);
      yield fork(fetchGroupsUsers, groupId);
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    yield put(UsersActions.removeUserFromGroupFailure(toError(err)));
  }
}

function* addUsersToGroup(users, groupId) {
  for (let i = 0; i < users.length; i += 1) {
    try {
      yield call(addUserToGroup, users[i], groupId);
    } catch (err) {
      console.log(`Error adding ${users[i]} to group ${groupId}`, err);
    }
  }

  yield put(UsersActions.addUsersToGroupSuccess());
}

function* removeUsersFromGroup(users, groupId) {
  for (let i = 0; i < users.length; i += 1) {
    try {
      yield call(removeUserFromGroup, users[i], groupId);
    } catch (err) {
      console.log(`Error removing ${users[i]} from group ${groupId}`, err);
    }
  }

  yield put(UsersActions.removeUsersFromGroupSuccess());
}

function* performByAction(action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_USERS_ENERTALK_PROFILE:
      return yield call(fetchUsersEnertalkProfile, payload.userId);
    case actionTypes.FETCH_USERS_OLIVINE_PROFILE:
      return yield call(fetchUsersOlivineProfile, payload.userId);
    case actionTypes.FETCH_USERS_PROFILE:
      return yield call(fetchUsersProfile, payload.userId);
    case actionTypes.ADD_USER_TO_GROUP:
      return yield call(addUserToGroup, payload.userId, payload.groupId);
    case actionTypes.REMOVE_USER_FROM_GROUP:
      return yield call(removeUserFromGroup, payload.userId, payload.groupId);
    case actionTypes.FETCH_USERS_ADAPTER_PROFILE:
      return yield call(fetchUsersAdapterProfile, payload.userId);
    case actionTypes.FETCH_USERS_INTEGRATION:
      return yield call(fetchUsersIntegration, payload.userId, payload.siteId);
    case actionTypes.ADD_USERS_TO_GROUP:
      return yield call(addUsersToGroup, payload.users, payload.groupId);
    case actionTypes.REMOVE_USERS_FROM_GROUP:
      return yield call(removeUsersFromGroup, payload.users, payload.groupId);
    default:
      return null;
  }
}

function* usersSaga() {
  yield takeEvery([
    actionTypes.FETCH_USERS_ENERTALK_PROFILE,
    actionTypes.FETCH_USERS_OLIVINE_PROFILE,
    actionTypes.FETCH_USERS_PROFILE,
    actionTypes.ADD_USER_TO_GROUP,
    actionTypes.REMOVE_USER_FROM_GROUP,
    actionTypes.FETCH_USERS_ADAPTER_PROFILE,
    actionTypes.FETCH_USERS_INTEGRATION,
    actionTypes.ADD_USERS_TO_GROUP,
    actionTypes.REMOVE_USERS_FROM_GROUP,
  ], performByAction);
}

export default usersSaga;
