import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { parse } from 'qs';
import { OAuthActions } from '../../redux/actions';
import * as OAuthSelectors from '../../redux/reducers/oauth';

class OAuth2Callback extends Component {
  componentWillMount() {
    const {
      returnedNonce,
      authorizationCode,
      error,
      exchangeToken,
    } = this.props;

    if (!error) {
      exchangeToken(returnedNonce, authorizationCode);
    }
  }

  render() {
    const {
      authenticated,
      referrer,
      error,
    } = this.props;

    if (authenticated) {
      return <Redirect to={referrer || '/'} />;
    }

    if (error) {
      return (
        <Redirect
          to={{
            pathname: '/auth',
            state: { error },
          }}
        />
      );
    }

    return (
      <h3>Please wait...</h3>
    );
  }
}

OAuth2Callback.propTypes = {
  authenticated: PropTypes.bool,
  error: PropTypes.string,
  returnedNonce: PropTypes.string,
  authorizationCode: PropTypes.string,
  exchangeToken: PropTypes.func.isRequired,
  referrer: PropTypes.string,
};

OAuth2Callback.defaultProps = {
  authenticated: false,
  error: null,
  returnedNonce: null,
  authorizationCode: null,
  referrer: '/',
};

function mapStateToProps(state, ownProps) {
  const querystring = ownProps.location.search && ownProps.location.search.replace('?', '');
  const {
    state: returnedNonce,
    code: authorizationCode,
    error_description: authorizationError,
  } = parse(querystring);
  const tokenError = OAuthSelectors.getError(state);

  return {
    authenticated: OAuthSelectors.authenticated(state),
    error: authorizationError || tokenError,
    returnedNonce,
    authorizationCode,
    referrer: OAuthSelectors.getReferrer(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    exchangeToken: OAuthActions.exchangeToken,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OAuth2Callback);
