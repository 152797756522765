import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Alert } from 'reactstrap';
import { OidUploadActions } from '../../redux/actions';
import * as UiSelectors from '../../redux/reducers/ui';
import { Button } from '../../components/shared/ui';
import './DrEventUpload.css';

class DrEventUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: '',
      showAlert: false,
      alertText: '',
      alertColor: 'primary',
    };

    this.redirectTimeout = null;
    this.startUpload = this.startUpload.bind(this);
    this.fileOnChange = this.fileOnChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      isLoading,
      success,
      history,
    } = this.props;
    const {
      fileName,
    } = this.state;

    if (
      isLoading !== prevProps.isLoading
      && success !== prevProps.success
      && !isLoading
      && success
    ) {
      return this.setState({
        showAlert: true,
        alertText: 'File successfully uploaded, redirecting to logs, please wait',
        alertColor: 'success',
      }, () => {
        this.redirectTimeout = setTimeout(() => history.push(`/dr/upload/logs?fileName=${fileName}`), 5000);
      });
    }

    if (
      isLoading !== prevProps.isLoading
      && success !== prevProps.success
      && !isLoading
      && !success
    ) {
      return this.setState({
        showAlert: true,
        alertText: 'Error uploading file, please try again',
        alertColor: 'danger',
      }, () => {
        this.redirectTimeout = setTimeout(() => this.setState({
          showAlert: false,
        }, 5000));
      });
    }

    if (
      isLoading !== prevProps.isLoading
      && isLoading
    ) {
      return this.setState({
        showAlert: true,
        alertText: 'Uploading file, please wait',
        alertColor: 'primary',
      });
    }
  }

  componentWillUnmount() {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
  }

  fileOnChange() {
    const { files } = this.uploadInput;

    if (files.length) {
      this.setState({
        fileName: files[0].name,
      });
    }
  }

  startUpload() {
    const { uploadFile } = this.props;
    const { files } = this.uploadInput;

    if (files.length) {
      const [file] = files;

      uploadFile(file, file.name);
    }
  }

  render() {
    const {
      fileName,
      alertText,
      showAlert,
      alertColor,
    } = this.state;
    const { isLoading } = this.props;

    return (
      <div className="DrEventUpload">
        <h3>
          Upload Performance File
        </h3>
        <Alert
          isOpen={showAlert}
          color={alertColor}
        >
          { alertText }
        </Alert>
        <div className="DrEventUpload__fileName">
          <h5>
            Selected file name:
            { ' ' }
            <b>
              { fileName ? fileName : 'Please select a file.' }
            </b>
          </h5>
        </div>
        <Input
          innerRef={(ref) => { this.uploadInput = ref; }}
          type="file"
          className="DrEventUpload__fileSelector"
          onChange={this.fileOnChange}
          disabled={isLoading}
        />
        <Button
          onClick={() => this.uploadInput.click()}
          disabled={isLoading}
        >
          Select File
        </Button>
        <Button
          onClick={this.startUpload}
          color="blue"
          disabled={isLoading}
        >
          Upload
        </Button>
      </div>
    );
  }
}

DrEventUpload.propTypes = {
  uploadFile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: UiSelectors.OidUpload.isLoading(state),
  success: UiSelectors.OidUpload.success(state),
});

export default connect(mapStateToProps, {
  uploadFile: OidUploadActions.uploadFile,
})(DrEventUpload);
