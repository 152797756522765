import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import './SimpleDataTable.css';

const SimpleDataTable = ({ header, data, className }) => (
  <div className={`SimpleDataTable${className ? ` ${className}` : ''}`}>
    { header ? (
      <h3>
        {header}
      </h3>
    ) : undefined }
    <Table striped>
      <tbody>
        { data.map(row => (
          <tr key={`${row.title}${row.content}`}>
            <th>
              { row.title }
            </th>
            <td>
              { row.content }
            </td>
          </tr>
        )) }
      </tbody>
    </Table>
  </div>
);

SimpleDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  })).isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
};

SimpleDataTable.defaultProps = {
  className: '',
  header: undefined,
};

export default SimpleDataTable;
