import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectBox } from '../../ui';
import * as GroupsSelectors from '../../../../redux/reducers/groups';
import './GroupsSelectList.css';

const GroupsSelectList = ({ selected, onChange, groups }) => (
  <div className="GroupsSelectList">
    {groups.map(({ id, name }) => (
      <div
        className="Group"
        key={id}
        onClick={() => onChange(id)}
        onKeyPress={() => onChange(id)}
        role="button"
        tabIndex={0}
      >
        <SelectBox selected={id === selected} />
        <div className="id">
          {id}
        </div>
        <div className="name">
          {name}
        </div>
      </div>
    ))}
  </div>
);

GroupsSelectList.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
};

GroupsSelectList.defaultProps = {
  selected: undefined,
};

const mapStateToProps = state => ({
  groups: GroupsSelectors.groups(state),
});

export default connect(mapStateToProps)(GroupsSelectList);
