import { actionTypes } from '../actions/users';

function UsersReducer(state = {}, action) {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.FETCH_USERS_ENERTALK_PROFILE_REQUEST:
    case actionTypes.ADD_USER_TO_GROUP_REQUEST:
    case actionTypes.REMOVE_USER_FROM_GROUP_REQUEST:
    case actionTypes.FETCH_USERS_ADAPTER_PROFILE_REQUEST:
    case actionTypes.FETCH_USERS_OLIVINE_PROFILE_REQUEST:
    case actionTypes.FETCH_USERS_INTEGRATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_USERS_ENERTALK_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        [payload.user.user_id]: {
          ...state[payload.user.user_id],
          enertalk: payload.user,
        },
      };
    case actionTypes.FETCH_USERS_ENERTALK_PROFILE_FAILURE:
    case actionTypes.FETCH_USERS_OLIVINE_PROFILE_FAILURE:
    case actionTypes.ADD_USER_TO_GROUP_FAILURE:
    case actionTypes.REMOVE_USER_FROM_GROUP_FAILURE:
    case actionTypes.FETCH_USERS_ADAPTER_PROFILE_FAILURE:
    case actionTypes.FETCH_USERS_INTEGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error.message,
      };
    case actionTypes.FETCH_USERS_OLIVINE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        [payload.user.profile.id]: {
          ...state[payload.user.profile.id],
          olivine: payload.user,
        },
      };
    case actionTypes.FETCH_USERS_INTEGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        [payload.integration.userId]: {
          ...state[payload.integration.userId],
          integration: payload.integration,
        },
      };
    case actionTypes.ADD_USER_TO_GROUP_SUCCESS:
    case actionTypes.REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.FETCH_USERS_ADAPTER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        [payload.adapter.userId]: {
          ...state[payload.adapter.userId],
          adapter: payload.adapter,
        },
      };
    default:
      return state;
  }
}

export default UsersReducer;

export const getUsers = state => state.users;
export const isLoading = state => state.users.loading;
