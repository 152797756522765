import { actionTypes } from '../actions/dr';

function DrReducer(state = {}, action) {
  const {
    type,
    payload,
  } = action;

  const newState = { ...state };

  switch (type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.FETCH_GROUPS_EVENTS_REQUEST:
    case actionTypes.POST_NEW_EVENT_REQUEST:
    case actionTypes.DELETE_EVENT_REQUEST:
    case actionTypes.FETCH_EVENT_REQUEST:
    case actionTypes.FETCH_EVENT_USERS_REQUEST:
    case actionTypes.FETCH_USER_EVENT_PERFORMANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_GROUPS_EVENTS_SUCCESS:
      if (!state.eventLists) {
        return {
          ...state,
          loading: false,
          eventLists: {
            [payload.groupId]: payload.events,
          },
        };
      }

      return {
        ...state,
        loading: false,
        eventLists: {
          ...state.eventLists,
          [payload.groupId]: payload.events,
        },
      };
    case actionTypes.FETCH_GROUPS_EVENTS_FAILURE:
    case actionTypes.POST_NEW_EVENT_FAILURE:
    case actionTypes.DELETE_EVENT_FAILURE:
    case actionTypes.FETCH_EVENT_FAILURE:
    case actionTypes.FETCH_EVENT_USERS_FAILURE:
    case actionTypes.FETCH_USER_EVENT_PERFORMANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case actionTypes.POST_NEW_EVENT_SUCCESS:
      for (let i = 0; i < payload.event.groups.length; i += 1) {
        newState.eventLists[payload.event.groups[i].groupId].events.push({
          id: payload.event.id,
          startTime: payload.event.startTime,
          endTime: payload.event.endTime,
          createdAt: (new Date()).toISOString(),
        });
      }

      return {
        ...newState,
        loading: false,
      };
    case actionTypes.DELETE_EVENT_SUCCESS:
      for (let i = 0; i < Object.keys(newState.eventLists).length; i += 1) {
        if (
          newState.eventLists[Object.keys(newState.eventLists)[i]].events
            &&
          newState.eventLists[Object.keys(newState.eventLists)[i]].events.findIndex(event => event.id === payload.eventId) > -1
        ) {
          newState.eventLists[Object.keys(newState.eventLists)[i]].events.splice(newState.eventLists[Object.keys(newState.eventLists)[i]].events.findIndex(event => event.id === payload.eventId), 1);
        }
      }

      delete newState.events[payload.eventId];

      return {
        ...newState,
        loading: false,
      };
    case actionTypes.FETCH_EVENT_SUCCESS:
      if (!state.events) {
        return {
          ...state,
          loading: false,
          events: {
            [payload.event.id]: payload.event,
          },
        };
      }

      return {
        ...state,
        loading: false,
        events: {
          ...state.events,
          [payload.event.id]: payload.event,
        },
      };
    case actionTypes.FETCH_EVENT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: {
          ...(state.users && state.users),
          [payload.eventId]: payload.users,
        },
      };
    case actionTypes.FETCH_USER_EVENT_PERFORMANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        performances: {
          ...(state.performances && state.performances),
          [payload.eventId]: {
            ...(state.performances && state.performances[payload.eventId]),
            ...((payload.performance && payload.performance.baseline) && {
              [payload.userId]: payload.performance,
            }),
          },
        },
      };
    default:
      return state;
  }
}

export default DrReducer;
export const getDrEventList = state => state.dr.eventLists || {};
export const getEvents = state => state.dr.events || {};
export const isLoading = state => state.dr.loading !== false;
export const getUsers = state => state.dr.users || {};
export const getPerformances = state => state.dr.performances || {};
