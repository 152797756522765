import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
import Init from './Init';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/fonts/Lato/latofonts.css';

const { document } = global;

ReactDOM.render(<Init />, document.getElementById('root'));

unregister();
