import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  CLEAR: '@oidUpload/CLEAR',
  FETCH_LOGS: '@oidUpload/FETCH_LOGS',
  FETCH_LOGS_REQUEST: '@oidUpload/FETCH_LOGS_REQUEST',
  FETCH_LOGS_SUCCESS: '@oidUpload/FETCH_LOGS_SUCCESS',
  FETCH_LOGS_FAILURE: '@oidUpload/FETCH_LOGS_FAILURE',
  UPLOAD_FILE: '@oidUpload/UPLOAD_FILE',
  UPLOAD_FILE_REQUEST: '@oidUpload/UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: '@oidUpload/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: '@oidUpload/UPLOAD_FILE_FAILURE',
};

export const clear = makeActionCreator(actionTypes.CLEAR);
export const fetchLogs = makeActionCreator(actionTypes.FETCH_LOGS, 'fileName');
export const fetchLogsRequest = makeActionCreator(actionTypes.FETCH_LOGS_REQUEST);
export const fetchLogsSuccess = makeActionCreator(actionTypes.FETCH_LOGS_SUCCESS, 'logs', 'fileName');
export const fetchLogsFailure = makeActionCreator(actionTypes.FETCH_LOGS_FAILURE, 'error', 'fileName');
export const uploadFile = makeActionCreator(actionTypes.UPLOAD_FILE, 'file', 'fileName');
export const uploadFileRequest = makeActionCreator(actionTypes.UPLOAD_FILE_REQUEST);
export const uploadFileSuccess = makeActionCreator(actionTypes.UPLOAD_FILE_SUCCESS);
export const uploadFileFailure = makeActionCreator(actionTypes.UPLOAD_FILE_FAILURE);

