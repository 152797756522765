import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  Router,
} from 'react-router-dom';
import {
  Tabs,
  Toolbar,
} from '../../components/shared/ui';
import DrEventDetailed from '../DrEventDetailed';
import DrEventPerformances from '../DrEventPerformances';
import { DrActions } from '../../redux/actions';
import * as DrSelectors from '../../redux/reducers/dr';
import './DrEventOverview.css';

class DrEventOverview extends Component {
  constructor(props) {
    super(props);

    this.toggleTabs = this.toggleTabs.bind(this);
  }

  componentDidMount() {
    const { fetchEvent, fetchUsers } = this.props;
    const { match: { params: { eventId } } } = this.props;

    fetchEvent(eventId);
    fetchUsers(eventId);
  }

  toggleTabs(tab) {
    const { history: { push, location: { pathname } } } = this.props;

    if (tab === 1 && pathname.endsWith('details')) {
      push(`${pathname.slice(0, pathname.length - 7)}performances`);
    } else if (tab === 0 && pathname.endsWith('performances')) {
      push(`${pathname.slice(0, pathname.length - 12)}details`);
    }
  }

  render() {
    const { history } = this.props;
    const { location: { pathname } } = history;

    return (
      <div className="DrEventOverview">
        <Toolbar
          left={(
            <Tabs
              tabs={['Details', 'Performances']}
              onChange={this.toggleTabs}
              selectedTab={Number(pathname.endsWith('performances'))}
            />
          )}
        />
        <div className="DrEventOverview__content">
          <Router history={history}>
            <Switch>
              <Route path="/dr/events/:eventId/details" component={DrEventDetailed} />
              <Route path="/dr/events/:eventId/performances" component={DrEventPerformances} />
            </Switch>
          </Router>
        </div>
      </div>
    );
  }
}

DrEventOverview.propTypes = {
  events: PropTypes.object.isRequired,
  fetchEvent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  events: DrSelectors.getEvents(state),
  users: DrSelectors.getUsers(state),
});

export default connect(mapStateToProps, {
  fetchEvent: DrActions.fetchEvent,
  fetchUsers: DrActions.fetchEventUsers,
})(DrEventOverview);
