import { actionTypes } from '../actions/user';

function UserReducer(state = {}, action) {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.FETCH_OLIVINE_USER_PROFILE_REQUEST:
    case actionTypes.FETCH_ENERTALK_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_ENERTALK_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        enertalk: payload.user,
      };
    case actionTypes.FETCH_ENERTALK_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        enertalk: {
          ...state.enertalk,
          error: payload.error.message,
        },
      };
    case actionTypes.FETCH_OLIVINE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        olivine: payload.user,
      };
    case actionTypes.FETCH_OLIVINE_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        olivine: {
          ...state.olivine,
          error: payload.error.message,
        },
      };
    default:
      return state;
  }
}

export default UserReducer;

export const getUserId = state => state.user.enertalk.user_id;
export const isAdmin = state => state.user.olivine &&
  state.user.olivine.groups &&
  state.user.olivine.groups.findIndex(group => group.id === 0) !== -1;
export const isLoading = state => state.user.loading;
