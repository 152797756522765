import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import './MessagesTable.css';

class MessagesTable extends Component {
  constructor(props) {
    super(props);

    this.renderMessages = this.renderMessages.bind(this);
  }

  renderMessages() {
    const { messages, onClick } = this.props;

    return messages.map(message => (
      <tr key={message.id} onClick={() => onClick(message.id)} className="message">
        <td>
          { message.id }
        </td>
        <td>
          { message.message.title }
        </td>
        <td>
          { message.destination.groupId }
        </td>
        <td>
          { new Date(message.status.scheduledAt).toLocaleString() }
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <div className="MessagesTable">
        <Table striped>
          <thead>
            <tr>
              <th>
                Task Name
              </th>
              <th>
                Title
              </th>
              <th>
                Group
              </th>
              <th>
                Scheduled time
              </th>
            </tr>
          </thead>
          <tbody>
            { this.renderMessages() }
          </tbody>
        </Table>
      </div>
    );
  }
}

MessagesTable.propTypes = {
  messages: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

MessagesTable.defaultProps = {
  onClick: () => {},
};

export default MessagesTable;
