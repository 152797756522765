import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { Modal } from '../../../shared/modals';
import { GroupsActions } from '../../../../redux/actions';
import * as GroupsSelectors from '../../../../redux/reducers/groups';
import * as UISelectors from '../../../../redux/reducers/ui';

class EditGroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameInput: '',
      tosInput: '',
      inputError: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { group, groups } = this.props;

    if (groups.length && !Number.isNaN(group)) {
      this.setState({
        nameInput: groups[group].name,
        tosInput: groups[group].tos,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { group, groups } = this.props;

    if (group !== prevProps.group) {
      if (groups.length && !Number.isNaN(group)) {
        this.setState({
          nameInput: groups[group].name,
          tosInput: groups[group].tos,
        });
      }
    }
  }

  handleChange(e) {
    return this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit() {
    const { nameInput, tosInput } = this.state;
    const { updateGroup, group } = this.props;

    this.setState({ inputError: '' });

    if (nameInput.length < 1) {
      this.setState({
        inputError: 'Please enter a name',
      });
    } else {
      updateGroup(group, nameInput, tosInput);
    }
  }

  toggle() {
    const { toggle } = this.props;

    this.setState({
      nameInput: '',
      tosInput: '',
      inputError: '',
    });

    return toggle();
  }

  render() {
    const {
      nameInput,
      tosInput,
      inputError,
    } = this.state;
    const {
      isOpen,
      error,
      loading,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen || !!loading}
        toggle={this.toggle}
        className="EditGroupModal"
        header="Edit Group"
        alert={inputError || error}
        alertColor="danger"
        footerButtons={[
          {
            title: 'Cancel',
            color: 'secondary',
            onClick: this.toggle,
            disabled: !!loading,
          },
          {
            title: 'Save',
            onClick: this.handleSubmit,
            disabled: !!loading,
          },
        ]}
      >
        <Form>
          <FormGroup>
            <Label>
              Name
            </Label>
            <Input type="text" value={nameInput} name="nameInput" onChange={this.handleChange} required />
          </FormGroup>
          <FormGroup>
            <Label>
              Link to Terms of Service
            </Label>
            <Input type="text" value={tosInput} name="tosInput" onChange={this.handleChange} />
          </FormGroup>
        </Form>
      </Modal>
    );
  }
}

EditGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
  group: PropTypes.number.isRequired,
  groups: PropTypes.array.isRequired,
};

EditGroupModal.defaultProps = {
  error: undefined,
  loading: undefined,
};

const mapStateToProps = state => ({
  loading: UISelectors.UpdateGroup.isLoading(state),
  error: UISelectors.UpdateGroup.error(state),
  groups: GroupsSelectors.groups(state),
});

export default connect(mapStateToProps, {
  updateGroup: GroupsActions.updateGroup,
})(EditGroupModal);
