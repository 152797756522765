import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  Router,
} from 'react-router-dom';
import { ResourceLoader } from '../../components';
import { SideNavBar, TopNavBar } from '../../containers';
import * as UiSelectors from '../../redux/reducers/ui';
import Groups from '../Groups';
import Users from '../Users';
import Messages from '../Messages';
import DREvents from '../DREvents';
import './Home.css';

function Home({ history, location, isCollapsed }) {
  return (
    <ResourceLoader>
      <div className={`Home${isCollapsed ? ' collapsed' : ''}`}>
        <TopNavBar location={location} />
        <SideNavBar location={location} />
        <div className="Home-Content">
          <Router history={history}>
            <Switch>
              <Route exact path="/groups" component={Groups} />
              <Route path="/groups/:groupId" component={Groups} />
              <Route exact path="/users/:userId" component={Users} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/messages" component={Messages} />
              <Route path="/messages/:messageId" component={Messages} />
              <Route exact path="/dr" component={DREvents} />
              <Route exact path="/dr/groups" component={DREvents} />
              <Route path="/dr/groups/:groupId" component={DREvents} />
              <Route path="/dr/events/:eventId" component={DREvents} />
              <Route exact path="/dr/upload" component={DREvents} />
              <Route exact path="/dr/upload/logs" component={DREvents} />
              <Redirect exact from="/" to="/groups" />
            </Switch>
          </Router>
        </div>
      </div>
    </ResourceLoader>
  );
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isCollapsed: UiSelectors.SideNav.isCollapsed(state),
});

export default connect(mapStateToProps)(Home);
