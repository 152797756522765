import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '../../components/shared/ui';
import { OAuthActions } from '../../redux/actions';
import './Authentication.css';

class Authentication extends Component {
  componentWillMount() {
    const {
      location,
      setReferrer,
    } = this.props;

    if (location.state && location.state.from) {
      setReferrer(location.state.from.pathname);
    }
  }

  render() {
    const {
      location: { state },
      signIn,
    } = this.props;
    const hasError = state && state.error;

    return (
      <div className="Authentication">
        <div className="enertalk-logo" />
        <div className="buttons">
          <Button className="sign-in" onClick={signIn}>Sign In</Button>
        </div>
        {hasError ? <p className="error-message">{state.error}</p> : null}
      </div>
    );
  }
}

Authentication.propTypes = {
  location: PropTypes.object,
  signIn: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  setReferrer: PropTypes.func.isRequired,
};

Authentication.defaultProps = {
  location: {},
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signIn: OAuthActions.signIn,
    signUp: OAuthActions.signUp,
    setReferrer: OAuthActions.setReferrer,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Authentication);
export { Authentication };
