import React from 'react';
import PropTypes from 'prop-types';
import { SelectBox } from '../shared/ui';
import './GroupCard.css';

const GroupCard = ({
  name,
  usersCount,
  onClick,
  onToggle,
  selected,
}) => (
  <button
    className="GroupCard"
    onClick={onToggle}
    type="button"
  >
    <div
      className="GroupCardBox"
    >
      <SelectBox selected={selected} />
      <div
        className="GroupCard__name"
        onClick={onClick}
      >
        { name }
      </div>
      <div className="GroupCard__users">
        { usersCount }
      </div>
    </div>
  </button>
);

GroupCard.propTypes = {
  name: PropTypes.string,
  usersCount: PropTypes.number,
  onClick: PropTypes.func,
  onToggle: PropTypes.func,
  selected: PropTypes.bool,
};

GroupCard.defaultProps = {
  name: '',
  usersCount: 0,
  onClick: () => {},
  onToggle: () => {},
  selected: false,
};

export default GroupCard;
