import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger({
    collapsed: true,
  }));
}

export default applyMiddleware(...middlewares);
export { sagaMiddleware };
