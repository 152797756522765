import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { GroupsSelectList } from '../../lists';
import { UsersActions } from '../../../../redux/actions';
import * as UISelectors from '../../../../redux/reducers/ui';

class AddUsersGroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: undefined,
    };

    this.selectGroup = this.selectGroup.bind(this);
    this.moveUsers = this.moveUsers.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loading, toggle } = this.props;

    if (!loading && prevProps.loading) {
      toggle();
    }
  }

  moveUsers() {
    const { selectedGroup } = this.state;
    const { users, addUsersToGroup } = this.props;

    addUsersToGroup(users, selectedGroup);
  }

  selectGroup(group) {
    this.setState({ selectedGroup: group });
  }

  render() {
    const { selectedGroup } = this.state;
    const { isOpen, toggle, users, loading } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        header={`Add User${users.length > 1 ? 's' : ''}`}
        footerButtons={[
          {
            title: 'Cancel',
            onClick: toggle,
            color: 'secondary',
          },
          {
            title: 'Add',
            disabled: selectedGroup === undefined || loading,
            onClick: this.moveUsers,
          },
        ]}
      >
        <span>
          Add user
          {users.length > 1 ? 's ' : ' '}
          to another group
        </span>
        <pre className="pre-scrollable" style={{whiteSpace: 'pre-wrap'}}>
          { users.join(', ') }
        </pre>
        <GroupsSelectList
          selected={selectedGroup}
          onChange={this.selectGroup}
        />
      </Modal>
    );
  }
}

AddUsersGroupModal.propTypes = {
  users: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  addUsersToGroup: PropTypes.func.isRequired,
};

AddUsersGroupModal.defaultProps = {
  isOpen: false,
  loading: false,
};

const mapStateToProps = state => ({
  loading: UISelectors.AddUsersToGroup.isLoading(state),
});

export default connect(mapStateToProps, {
  addUsersToGroup: UsersActions.addUsersToGroup,
})(AddUsersGroupModal);
