import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Button } from '../../components/shared/ui';
import { OidUploadActions } from '../../redux/actions';
import * as OidUploadSelectors from '../../redux/reducers/oidUpload';
import * as UiSelectors from '../../redux/reducers/ui';
import { Loading } from '../../components';
import './DrEventUploadLogs.css';

class DrEventUploadLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: '',
    };

    this.fetchLogs = this.fetchLogs.bind(this);
  }

  componentDidMount() {
    const { history, location } = this.props;
    const { fileName } = queryString.parse(location.search);

    if (!fileName) history.push('/dr/upload');

    this.setState({ fileName }, this.fetchLogs);

  }

  fetchLogs() {
    const { fetchLogs } = this.props;
    const { fileName } = this.state;

    return fetchLogs(fileName);
  }

  render() {
    const { logs, isLoading } = this.props;
    const { fileName } = this.state;

    if (isLoading || !logs[fileName]) return <Loading />;

    const uploadLogs = logs[fileName];

    if (!logs[fileName].executionId) {
      return (
        <div className="DrEventUploadLogs">
          <div className="DrEventUploadLogs__error">
            <h3>
              No logs found for
              {' '}
              <b>
                {fileName}
              </b>
            </h3>
            <Button
              onClick={this.fetchLogs}
              color="blue"
            >
              Reload
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="DrEventUploadLogs">
        <h3>
          File name:
          {' '}
          <b>
            {fileName}
          </b>
        </h3>
        <h3>
          Execution ID:
          {' '}
          <b>
            {uploadLogs.executionId}
          </b>
        </h3>
        <Button
          onClick={this.fetchLogs}
          color="blue"
        >
          Reload
        </Button>
        <pre className="pre-scrollable">
          {uploadLogs.logs.join('\n')}
        </pre>
      </div>
    );
  }
}

DrEventUploadLogs.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logs: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  logs: OidUploadSelectors.getLogs(state),
  isLoading: UiSelectors.OidLogsLoading(state),
});

export default connect(mapStateToProps, {
  fetchLogs: OidUploadActions.fetchLogs,
})(DrEventUploadLogs);
