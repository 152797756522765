import { combineReducers } from 'redux';
import { actionTypes as groupsActionTypes } from '../actions/groups';
import { actionTypes as drActionTypes } from '../actions/dr';
import { actionTypes as messagesActionTypes } from '../actions/messages';
import { actionTypes as userActionTypes } from '../actions/user';
import { actionTypes as oauthActionTypes } from '../actions/oauth';
import { actionTypes as usersActionTypes } from '../actions/users';
import { actionTypes as uiActionTypes } from '../actions/ui';
import { actionTypes as oidUploadActionTypes } from '../actions/oidUpload';

function NewGroupReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case groupsActionTypes.CREATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case groupsActionTypes.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case groupsActionTypes.CREATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

function UpdateGroupReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case groupsActionTypes.UPDATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case groupsActionTypes.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case groupsActionTypes.UPDATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

function NewDrEventReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case drActionTypes.POST_NEW_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case drActionTypes.POST_NEW_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case drActionTypes.POST_NEW_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

function DeleteDrEventReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case drActionTypes.DELETE_EVENT:
      return {
        ...state,
        loading: true,
        error: undefined,
        affected: undefined,
      };
    case drActionTypes.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        affected: payload.affected,
      };
    case drActionTypes.DELETE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
        affected: undefined,
      };
    default:
      return state;
  }
}

function NewMessageReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case messagesActionTypes.SEND_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case messagesActionTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case messagesActionTypes.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

function InitialLaunchReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case oauthActionTypes.READY:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case userActionTypes.FETCH_ENERTALK_USER_PROFILE_FAILURE:
    case userActionTypes.FETCH_OLIVINE_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case userActionTypes.FETCH_OLIVINE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    default:
      return state;
  }
}

function AddUsersToGroupReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case usersActionTypes.ADD_USERS_TO_GROUP:
      return {
        ...state,
        loading: true,
      };
    case usersActionTypes.ADD_USERS_TO_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

function RemoveUsersFromGroupReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case usersActionTypes.REMOVE_USERS_FROM_GROUP:
      return {
        ...state,
        loading: true,
      };
    case usersActionTypes.REMOVE_USERS_FROM_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

function SideNavReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case uiActionTypes.SET_SIDENAV:
      return {
        ...state,
        collapsed: payload.collapsed,
      };
    default:
      return state;
  }
}

function OidUploadReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case oidUploadActionTypes.UPLOAD_FILE_REQUEST:
      return {
        loading: true,
      };
    case oidUploadActionTypes.UPLOAD_FILE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case oidUploadActionTypes.UPLOAD_FILE_FAILURE:
      return {
        loading: false,
        success: false,
      };
    default:
      return state;
  }
}

function OidLogsLoadingReducer(state = false, action) {
  const { type } = action;

  switch (type) {
    case oidUploadActionTypes.FETCH_LOGS_REQUEST:
      return true;
    case oidUploadActionTypes.FETCH_LOGS_SUCCESS:
    case oidUploadActionTypes.FETCH_LOGS_FAILURE:
      return false;
    default:
      return state;
  }
}

const UIReducer = combineReducers({
  NewGroup: NewGroupReducer,
  UpdateGroup: UpdateGroupReducer,
  NewDrEvent: NewDrEventReducer,
  DeleteDrEvent: DeleteDrEventReducer,
  NewMessage: NewMessageReducer,
  InitialLaunch: InitialLaunchReducer,
  AddUsersToGroup: AddUsersToGroupReducer,
  RemoveUsersFromGroup: RemoveUsersFromGroupReducer,
  SideNav: SideNavReducer,
  OidUpload: OidUploadReducer,
  OidLogsLoading: OidLogsLoadingReducer,
});

export default function (state, action) {
  if (action.type === '@oauth/CLEAR') {
    return UIReducer({}, action);
  }

  return UIReducer(state, action);
}

export const OidLogsLoading = state => state.ui.OidLogsLoading === true;

export const OidUpload = {
  isLoading: state => state.ui.OidUpload.loading === true,
  success: state => state.ui.OidUpload.success === true,
};

export const NewGroup = {
  isLoading: state => state.ui.NewGroup.loading,
  error: state => state.ui.NewGroup.error,
};

export const UpdateGroup = {
  isLoading: state => state.ui.UpdateGroup.loading,
  error: state => state.ui.UpdateGroup.error,
};

export const NewDrEvent = {
  isLoading: state => state.ui.NewDrEvent.loading,
  error: state => state.ui.NewDrEvent.error,
};

export const DeleteDrEvent = {
  isLoading: state => state.ui.DeleteDrEvent.loading,
  affected: state => state.ui.DeleteDrEvent.affected,
  error: state => state.ui.DeleteDrEvent.error,
};

export const NewMessage = {
  isLoading: state => state.ui.NewMessage.loading,
  error: state => state.ui.NewMessage.error,
};

export const InitialLaunch = {
  isLoading: state => state.ui.InitialLaunch.loading,
  error: state => state.ui.InitialLaunch.error,
};

export const AddUsersToGroup = {
  isLoading: state => state.ui.AddUsersToGroup.loading,
};

export const RemoveUsersFromGroup = {
  isLoading: state => state.ui.RemoveUsersFromGroup.loading,
};

export const SideNav = {
  isCollapsed: state => !!state.ui.SideNav.collapsed,
};
