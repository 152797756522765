import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as OAuthSelectors from '../../redux/reducers/oauth';

function PrivateRoute(props) {
  const {
    authenticated,
    component: PrivateContent,
    location,
    ...rest
  } = props;

  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/auth',
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps => <PrivateContent {...routeProps} />}
    />
  );
}

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  noLayout: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  noLayout: false,
};

function mapStateToProps(state) {
  return {
    authenticated: OAuthSelectors.authenticated(state),
  };
}

export default connect(mapStateToProps)(PrivateRoute);
