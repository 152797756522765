import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { UsersActions } from '../../../../redux/actions';
import * as UISelectors from '../../../../redux/reducers/ui';
import * as UsersSelectors from '../../../../redux/reducers/users';
import * as GroupsSelectors from '../../../../redux/reducers/groups';

class RemoveUsersGroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      soloUsers: [],
    };

    this.checkUsers = this.checkUsers.bind(this);
    this.fetchUserProfiles = this.fetchUserProfiles.bind(this);
    this.removeUsers = this.removeUsers.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loading, toggle, isOpen, onComplete } = this.props;

    if (!loading && prevProps.loading) {
      onComplete();
      toggle();
    } else if (isOpen && !prevProps.isOpen) {
      this.fetchUserProfiles();
    }
  }

  fetchUserProfiles() {
    const { users, userProfiles, getUsersOlivineProfile } = this.props;

    for (let i = 0; i < users.length; i += 1) {
      if (!userProfiles[users[i]] || !userProfiles[users[i]].olivine) {
        getUsersOlivineProfile(users[i]);
      }
    }
  }

  checkUsers() {
    const { users, userProfiles } = this.props;
    const soloUsers = [];

    for (let i = 0; i < users.length; i += 1) {
      if (userProfiles[users[i]] && userProfiles[users[i]].olivine && userProfiles[users[i]].olivine.profile.groups.length < 2) {
        soloUsers.push(users[i]);
      }
    }

    return soloUsers;
  }

  removeUsers() {
    const { users, group, removeUsersFromGroup } = this.props;

    removeUsersFromGroup(users, group);
  }

  render() {
    const {
      isOpen,
      toggle,
      users,
      group,
      loading,
      groups,
      userProfilesLoading,
    } = this.props;
    const currentGroup = groups[group] || {};
    const soloUsers = this.checkUsers();

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        header={`Remove User${users.length > 1 ? 's' : ''}`}
        footerButtons={[
          {
            title: 'Cancel',
            onClick: toggle,
            color: 'secondary',
          },
          {
            title: 'Remove',
            color: 'danger',
            disabled: loading || userProfilesLoading,
            onClick: this.removeUsers,
          },
        ]}
      >
        <span>
          Remove user
          {users.length > 1 ? 's ' : ' '}
          from group
          {` ${group} - ${currentGroup.name}`}
        </span>
        <pre className="pre-scrollable" style={{ whiteSpace: 'pre-wrap' }}>
          { users.join(', ') }
        </pre>
        { soloUsers.length ? (
          <Fragment>
            <h5>
              Warning
            </h5>
            <span>
              The user
              { soloUsers.length > 1 ? 's ' : ' '}
              below
              { soloUsers.length > 1 ? " don't " : " doesn't "}
              belong to any other group. Removing
              { soloUsers.length > 1 ? ' these users ' : ' this user '}
              from this group may cause unexpected issues.
            </span>
            <pre className="pre-scrollable" style={{ whiteSpace: 'pre-wrap' }}>
              { soloUsers.join(', ') }
            </pre>
          </Fragment>
        ) : undefined }
      </Modal>
    );
  }
}

RemoveUsersGroupModal.propTypes = {
  users: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  group: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  userProfiles: PropTypes.object.isRequired,
  getUsersOlivineProfile: PropTypes.func.isRequired,
  removeUsersFromGroup: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  userProfilesLoading: PropTypes.bool,
  onComplete: PropTypes.func,
};

RemoveUsersGroupModal.defaultProps = {
  isOpen: false,
  loading: false,
  userProfilesLoading: false,
  onComplete: () => {},
};

const mapStateToProps = state => ({
  groups: GroupsSelectors.groups(state),
  userProfiles: UsersSelectors.getUsers(state),
  userProfilesLoading: UsersSelectors.isLoading(state),
  loading: UISelectors.RemoveUsersFromGroup.isLoading(state),
});

export default connect(mapStateToProps, {
  getUsersOlivineProfile: UsersActions.fetchUsersOlivineProfile,
  removeUsersFromGroup: UsersActions.removeUsersFromGroup,
})(RemoveUsersGroupModal);
