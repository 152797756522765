import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import * as GroupsSelectors from '../../redux/reducers/groups';
import Loading from '../Loading';
import './GroupsList.css';

function GroupsList({
  groups,
  clickHandle,
  className,
  isLoading,
  selectedGroup,
}) {
  if (isLoading) return <Loading />;

  return (
    <ListGroup className={`GroupsList${className ? ` ${className}` : ''}`}>
      {groups.map(group => (
        <ListGroupItem
          onClick={() => clickHandle(group.id)}
          key={group.id}
          className={Number(selectedGroup) === group.id ? 'selected' : undefined}
        >
          <div className="id">
            {group.id}
          </div>
          {group.name}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}

GroupsList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tos: PropTypes.string,
    }),
  ),
  clickHandle: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedGroup: PropTypes.string,
};

GroupsList.defaultProps = {
  groups: [],
  clickHandle: () => {},
  className: undefined,
  isLoading: undefined,
  selectedGroup: undefined,
};

const mapStateToProps = state => ({
  groups: GroupsSelectors.groups(state),
  isLoading: GroupsSelectors.isLoading(state),
});

export default connect(mapStateToProps)(GroupsList);
