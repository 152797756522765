import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { UsersTable } from '../../../components/Users';
import { Toolbar, Button } from '../../../components/shared/ui';
import { AddUsersGroupModal } from '../../../components/shared/modals';
import * as GroupsSelectors from '../../../redux/reducers/groups';
import { GroupsActions } from '../../../redux/actions';
import './UsersList.css';

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      showAddUsersModal: false,
      gotoInput: '',
    };

    this.getToolbarButtons = this.getToolbarButtons.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFilteredChange = this.handleFilteredChange.bind(this);
    this.handleSortedChange = this.handleSortedChange.bind(this);
    this.handleClickUser = this.handleClickUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { fetchGroupsUsers } = this.props;
    fetchGroupsUsers();
  }

  getToolbarButtons() {
    const {
      refetchGroup,
      isLoadingUsers,
      users,
    } = this.props;
    const {
      selected,
    } = this.state;

    return [
      {
        name: 'Refresh',
        onClick: () => refetchGroup(undefined, users.allUsers ? Math.floor(users.allUsers.offset / 20) : 0),
        disabled: isLoadingUsers,
      },
      {
        name: selected.length ? `Add (${selected.length}) to group` : 'Add to group',
        onClick: () => this.setState({ showAddUsersModal: true }),
        disabled: !selected.length,
      },
    ];
  }

  toggleSelect(id) {
    const { selected } = this.state;

    if (selected.indexOf(id) > -1) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }

    this.setState({ selected: selected.slice() });
  }

  clearSelected() {
    return this.setState({ selected: [] });
  }

  handlePageChange(pageIndex) {
    const { fetchGroupsUsers } = this.props;

    this.clearSelected();
    return fetchGroupsUsers(undefined, Math.ceil(pageIndex));
  }

  handleFilteredChange(params) {
    const { fetchGroupsUsers } = this.props;

    this.clearSelected();
    return fetchGroupsUsers(undefined, undefined, undefined, undefined, params.onboarding, params.tos, params.zipcode);
  }

  handleSortedChange(sortBy, orderBy) {
    const { fetchGroupsUsers } = this.props;

    this.clearSelected();
    return fetchGroupsUsers(undefined, undefined, sortBy, orderBy);
  }

  handleClickUser(userId) {
    const { history } = this.props;

    return history.push(`/users/${userId}`);
  }

  handleChange(e) {
    return this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const {
      selected,
      showAddUsersModal,
      gotoInput,
    } = this.state;
    const {
      users,
      isLoadingUsers,
    } = this.props;

    const groupId = 'allUsers';

    const currentUsers = users[groupId] ? users[groupId].users : [];
    const filters = users[groupId] ? users[groupId].filters : {};
    const currentSortBy = users[groupId] ? users[groupId].sortBy : undefined;
    const currentOrderBy = users[groupId] ? users[groupId].orderBy : undefined;

    const currentOffset = users[groupId] ? users[groupId].offset : 0;
    const currentTotalCount = users[groupId] ? users[groupId].totalCount : 0;

    return (
      <div className="UsersList">
        <AddUsersGroupModal
          users={selected}
          isOpen={showAddUsersModal}
          toggle={() => this.setState({ showAddUsersModal: false })}
        />
        <Toolbar
          className="UsersList__Toolbar"
          left={(
            <div className="gotoUser">
              <Input
                className="gotoUser-input"
                type="number"
                placeholder="EnerTalk User ID"
                name="gotoInput"
                onChange={this.handleChange}
              />
              <Button className="submit-gotoUser" color="blue" onClick={() => this.handleClickUser(gotoInput)}>
                Go to User
              </Button>
            </div>
          )}
          buttons={this.getToolbarButtons()}
        />
        <UsersTable
          users={currentUsers}
          filters={filters}
          selected={selected}
          offset={currentOffset}
          totalCount={currentTotalCount}
          onPageChange={this.handlePageChange}
          onFilteredChange={this.handleFilteredChange}
          onSortedChange={this.handleSortedChange}
          sortBy={currentSortBy}
          orderBy={currentOrderBy}
          onSelectUser={this.toggleSelect}
          onClickUser={this.handleClickUser}
          loading={isLoadingUsers}
          className="UsersList__table"
        />
      </div>
    );
  }
}

UsersList.propTypes = {
  fetchGroupsUsers: PropTypes.func.isRequired,
  refetchGroup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  users: PropTypes.object,
  isLoadingUsers: PropTypes.bool,
};

UsersList.defaultProps = {
  users: {},
  isLoadingUsers: false,
};

const mapStateToProps = state => ({
  users: GroupsSelectors.users(state),
  isLoadingUsers: GroupsSelectors.isLoadingUsers(state),
});

export default connect(mapStateToProps, {
  fetchGroupsUsers: GroupsActions.fetchGroupsUsers,
  refetchGroup: GroupsActions.refetchGroup,
})(UsersList);
