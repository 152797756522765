const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

function exchangeToken(code) {
  const endpoint = `${authDomain}/token`;
  const basicHeader = window.btoa(`${clientId}:${clientSecret}`);

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${basicHeader}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'authorization_code',
      code,
    }),
  })
    .then(resp => resp.json())
    .then(data => ({
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
    }));
}

function verifyToken(accessToken) {
  const endpoint = `${authDomain}/verify`;

  return fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(resp => resp.json());
}

function refreshAccessToken(refreshToken) {
  const endpoint = `${authDomain}/token`;
  const basicHeader = window.btoa(`${clientId}:${clientSecret}`);

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${basicHeader}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  })
    .then(resp => resp.json())
    .then(data => ({
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
    }));
}

function changePassword(accessToken, oldPassword, newPassword) {
  const endpoint = `${authDomain}/users/password`;
  const payload = {
    oldPassword,
    newPassword,
  };

  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(resp => resp.json());
}

export {
  exchangeToken,
  verifyToken,
  refreshAccessToken,
  changePassword,
};
