import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { DeleteDrEventModal, NewDrEventModal } from '../../components/DrEvents/modals';
import { Toolbar, SelectBox } from '../../components/shared/ui';
import { DrActions } from '../../redux/actions';
import * as DrSelectors from '../../redux/reducers/dr';
import './GroupsDrEvents.css';

class GroupsDrEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewDrEventModal: false,
      selectedEvent: null,
      showDeleteDrEventModal: false,
    };

    this.loadEvents = this.loadEvents.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.toggleNewDrEventModal = this.toggleNewDrEventModal.bind(this);
    this.toggleDeleteDrEventModal = this.toggleDeleteDrEventModal.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.getToolbarButtons = this.getToolbarButtons.bind(this);
  }

  componentDidMount() {
    this.loadEvents();
  }

  componentDidUpdate(prevProps) {
    const { groupId } = this.props;

    if (prevProps.groupId !== groupId) {
      this.setState({ selectedEvent: null });
      this.loadEvents();
    }
  }

  toggleNewDrEventModal(bool) {
    if (typeof bool === 'boolean') {
      return this.setState({
        showNewDrEventModal: bool,
      });
    }

    const { showNewDrEventModal } = this.state;

    return this.setState({
      showNewDrEventModal: !showNewDrEventModal,
    });
  }

  toggleDeleteDrEventModal(bool) {
    if (typeof bool === 'boolean') {
      return this.setState({
        showDeleteDrEventModal: bool,
      });
    }

    const { showNewDrEventModal } = this.state;

    return this.setState({
      showDeleteDrEventModal: !showNewDrEventModal,
    });
  }

  loadEvents() {
    const { groupId, fetchGroupsEvents, drEvents } = this.props;

    if (!drEvents[groupId]) fetchGroupsEvents(groupId);
  }

  getToolbarButtons() {
    const { fetchGroupsEvents, groupId, isLoading } = this.props;
    const { selectedEvent } = this.state;

    const optionalButtons = [];

    if (selectedEvent !== null) {
      optionalButtons.push({
        name: 'Delete',
        onClick: () => this.toggleDeleteDrEventModal(true),
        color: 'danger',
      });
    }

    return [
      ...optionalButtons,
      {
        name: 'Refresh',
        onClick: () => fetchGroupsEvents(groupId),
        disabled: isLoading,
      },
      {
        name: 'New',
        onClick: () => this.toggleNewDrEventModal(true),
      },
    ];
  }

  handleSelectEvent(eventId) {
    this.setState({
      selectedEvent: eventId,
    });
  }

  renderTable() {
    const { drEvents, groupId, onClick } = this.props;
    const { selectedEvent } = this.state;

    return (
      <Table className="GroupsDrEvents__eventsTable" striped>
        <thead>
          <tr>
            <th />
            <th>#</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          { drEvents[groupId].events.map(event => (
            <tr key={event.id} className="event">
              <td onClick={() => this.handleSelectEvent(event.id)}>
                <SelectBox selected={event.id === selectedEvent} />
              </td>
              <td onClick={() => onClick(event.id)}>
                { event.id }
              </td>
              <td onClick={() => onClick(event.id)}>
                { (new Date(event.startTime)).toLocaleString() }
              </td>
              <td onClick={() => onClick(event.id)}>
                { (new Date(event.endTime)).toLocaleString() }
              </td>
              <td onClick={() => onClick(event.id)}>
                { (new Date(event.createdAt)).toLocaleString() }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  render() {
    const { groupId, drEvents } = this.props;
    const { showNewDrEventModal, showDeleteDrEventModal, selectedEvent } = this.state;

    return (
      <div className="GroupsDrEvents">
        <Toolbar
          className="GroupsDrEvents__Toolbar"
          buttons={this.getToolbarButtons()}
        />
        { drEvents[groupId] ? this.renderTable() : undefined}
        <NewDrEventModal
          isOpen={showNewDrEventModal}
          toggle={this.toggleNewDrEventModal}
          groupId={groupId}
        />
        <DeleteDrEventModal
          isOpen={showDeleteDrEventModal}
          toggle={this.toggleDeleteDrEventModal}
          eventId={selectedEvent}
        />
      </div>
    );
  }
}

GroupsDrEvents.propTypes = {
  groupId: PropTypes.string.isRequired,
  fetchGroupsEvents: PropTypes.func.isRequired,
  drEvents: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

GroupsDrEvents.defaultProps = {
  isLoading: false,
};

const mapStateToProps = state => ({
  drEvents: DrSelectors.getDrEventList(state),
  isLoading: DrSelectors.isLoading(state),
});

export default connect(mapStateToProps, {
  fetchGroupsEvents: DrActions.fetchGroupsEvents,
})(GroupsDrEvents);
