import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './UserLink.css';

const UserLink = ({ userId, className }) => (
  <Link
    to={`/users/${userId}`}
    className={`UserLink${className ? ` ${className}` : ''}`}
  >
    { userId }
  </Link>
);

UserLink.propTypes = {
  userId: PropTypes.number.isRequired,
  className: PropTypes.string,
};

UserLink.defaultProps = {
  className: '',
};

export default UserLink;
