import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import App from './App';
import { store, persistor } from './store';
import rootSaga from './redux/sagas';
import { Loading } from './components';

class Init extends React.Component {
  componentWillMount() {
    store.runSaga(rootSaga);
  }

  render() {
    return (
      <PersistGate
        persistor={persistor}
        loading={<Loading />}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </PersistGate>
    );
  }
}

export default Init;
