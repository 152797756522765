import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../components/shared/ui';
import { Link } from 'react-router-dom';
import { OAuthActions, UiActions } from '../../redux/actions';
import * as UiSelectors from '../../redux/reducers/ui';
import './TopNavBar.css';

class TopNavBar extends Component {
  constructor(props) {
    super(props);

    this.renderPathBar = this.renderPathBar.bind(this);
    this.renderInitialPath = this.renderInitialPath.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
  }

  renderGroup() {
    const { location: { pathname } } = this.props;
    const paths = pathname.split('/').filter(path => path);

    if (paths[0] && paths[0].toLowerCase() === 'dr') paths.shift();

    let group = paths[1];

    if (paths[0] !== 'upload' && group === undefined) return null;

    let iconClass;
    let tab;

    switch (paths[0]) {
      case 'users':
        iconClass = 'users';
        tab = 'User';
        break;
      case 'messages':
        iconClass = 'messages';
        tab = 'Message';
        break;
      case 'events':
        iconClass = 'dr';
        tab = 'Event';
        break;
      case 'upload':
        iconClass = 'dr';
        tab = 'Upload Performance';
        group = undefined;
        break;
      default:
        iconClass = 'groups';
        tab = 'Group';
    }

    return (
      <div className="Path" key={pathname}>
        <div className={`Path__icon ${iconClass}`} />
        <div className="Path__name">
          { tab }
          { group ? ` ${group}` : '' }
        </div>
      </div>
    );
  }

  renderInitialPath() {
    const { location: { pathname } } = this.props;
    const path = pathname.split('/')[1].toLowerCase();

    if (!path) return undefined;

    let pathName;

    switch (path) {
      case 'dr':
        pathName = 'Demand Response';
        break;
      default:
        pathName = path;
    }

    return (
      <Link
        to={`/${path}`}
        className="Path"
        key={path}
      >
        <div className={`Path__icon ${path}`} />
        <div className="Path__name">
          {pathName}
        </div>
      </Link>
    );
  }

  renderPathBar() {
    const renderElms = [];

    [
      this.renderInitialPath(),
      this.renderGroup(),
    ]
      .filter(elm => elm)
      .forEach((elm, i, { length }) => {
        renderElms.push(elm);

        if (i < length - 1) {
          renderElms.push(
            <div className="Seperator" key={i}>
              /
            </div>,
          );
        }
      });

    return (
      <div className="PathBar">
        { renderElms }
      </div>
    );
  }

  render() {
    const { signOut, toggleCollapse, isCollapsed } = this.props;

    return (
      <div className={`TopNavBar${isCollapsed ? ' collapsed' : ''}`}>
        <div className="TopBar">
          <button
            className={`hamburger${isCollapsed ? ' collapsed' : ''}`}
            type="button"
            onClick={toggleCollapse}
          />
          <Button
            className="TopNavBar__signOut"
            onClick={signOut}
            color="danger"
          >
            Log off
          </Button>
        </div>
        { this.renderPathBar() }
      </div>
    );
  }
}

TopNavBar.propTypes = {
  signOut: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isCollapsed: UiSelectors.SideNav.isCollapsed(state),
});

export default connect(mapStateToProps, {
  signOut: OAuthActions.signOut,
  toggleCollapse: UiActions.toggleSidenav,
})(TopNavBar);
