import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Loading } from '../../components';
import { UserLink } from '../../components/shared/ui';
import * as DrSelectors from '../../redux/reducers/dr';
import { DrActions } from '../../redux/actions';
import './DrEventPerformances.css';

class DrEventPerformances extends Component {
  constructor(props) {
    super(props);

    this.renderUsers = this.renderUsers.bind(this);
    this.renderUser = this.renderUser.bind(this);
  }

  componentDidMount() {
    const { getPerformances, match: { params: { eventId } } } = this.props;

    getPerformances(eventId);
  }

  renderUser(userId, i) {
    const {
      users,
      performances,
      match: { params: { eventId } },
      events,
    } = this.props;
    const { users: selectedUsers } = users[eventId];

    if (!selectedUsers[i].optIn) {
      return (
        <tr key={userId}>
          <td>
            <UserLink userId={userId} />
          </td>
          <td>
            Opted Out
          </td>
          <td />
          <td />
          <td />
        </tr>
      )
    }

    if (!performances[eventId] ||
      !performances[eventId][userId]
    ) {
      return (
        <tr key={userId}>
          <td>
            <UserLink userId={userId} />
          </td>
          <td>
            Pending
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    }

    const event = events[eventId];
    const userPerformance = performances[eventId][userId];
    const overallBaseline = userPerformance.baseline.filter(
      data => new Date(data.startTime) >= new Date(event.startTime) &&
      new Date(data.endTime) <= new Date(event.endTime),
    ).reduce((p, c) => p + c.value, 0);
    const overallPerformance = userPerformance.eventPerformance.filter(
      data => new Date(data.startTime) >= new Date(event.startTime) &&
      new Date(data.endTime) <= new Date(event.endTime),
    ).reduce((p, c) => p + c.value, 0);
    const overallMetered = userPerformance.meteredPower.filter(
      data => new Date(data.startTime) >= new Date(event.startTime) &&
      new Date(data.endTime) <= new Date(event.endTime),
    ).reduce((p, c) => p + c.value, 0);
    const { uom } = userPerformance.baseline[0];

    const success = overallBaseline > overallMetered;

    return (
      <tr key={userId}>
        <td>
          <UserLink userId={userId} />
        </td>
        <td>
          { success ? 'Yes' : 'Failed' }
        </td>
        <td>
          { `${overallMetered} ${uom}` }
        </td>
        <td>
          { `${overallBaseline} ${uom}` }
        </td>
        <td>
          { `${overallPerformance} ${uom}` }
        </td>
      </tr>
    );
  }

  renderUsers() {
    const { users, match: { params: { eventId } } } = this.props;

    const { users: selectedUsers } = users[eventId];

    return selectedUsers.map((user, i) => this.renderUser(user.id, i));
  }

  render() {
    const { users, match: { params: { eventId } } } = this.props;

    const selectedUsers = users[eventId];

    if (!selectedUsers) return <Loading />;

    return (
      <div className="DrEventPerformances">
        <Table striped>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Success</th>
              <th>Overall Metered Data</th>
              <th>Overall Baseline</th>
              <th>Overall Performance</th>
            </tr>
          </thead>
          <tbody>
            { this.renderUsers() }
          </tbody>
        </Table>
      </div>
    );
  }
}

DrEventPerformances.propTypes = {
  users: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  performances: PropTypes.object.isRequired,
  getPerformances: PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  users: DrSelectors.getUsers(state),
  performances: DrSelectors.getPerformances(state),
  events: DrSelectors.getEvents(state),
});

export default connect(mapStateToProps, {
  getPerformances: DrActions.fetchAllEventPerformnace,
})(DrEventPerformances);
