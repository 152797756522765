import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UiActions } from '../../../redux/actions';
import * as UiSelectors from '../../../redux/reducers/ui';
import './ExpandingSideNav.css';

class ExpandingSideNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.handleMainClick = this.handleMainClick.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isCollapsed } = this.props;
    const { expanded } = this.state;

    if (isCollapsed !== prevProps.isCollapsed && isCollapsed && expanded) {
      this.setState({ expanded: false });
    }
  }

  toggleExpand() {
    const { expanded } = this.state;

    return this.setState({ expanded: !expanded });
  }

  handleMainClick(e) {
    const { isCollapsed, toggleCollapse } = this.props;
    const { expanded } = this.state;

    e.preventDefault();

    if (!expanded && isCollapsed) toggleCollapse();
    if (!expanded) this.setState({ expanded: true });
  }

  renderChildren() {
    const { subNavs } = this.props;
    const { expanded } = this.state;

    const children = subNavs.map(subNav => (
      <NavLink
        to={subNav.to}
        className={`ExpandingSideNav__item ${subNav.className}`}
        activeClassName="active"
        key={subNav.className}
      >
        <div className="ExpandingSideNav__itemLogo" />
        <div className="ExpandingSideNav__itemTitle">
          { subNav.name }
        </div>
      </NavLink>
    ));

    return (
      <div className={`ExpandingSideNav__children${expanded ? ' expanded' : ''}`}>
        { children }
      </div>
    );
  }

  render() {
    const {
      mainTo,
      className,
      name,
      isCollapsed,
    } = this.props;
    const { expanded } = this.state;

    return (
      <div className={`ExpandingSideNav ${className}${isCollapsed ? ' collapsed' : ''}${expanded ? ' expanded' : ''}`}>
        <NavLink
          to={mainTo}
          className="ExpandingSideNav__item ExpandingSideNav__mainItem"
          activeClassName="active"
          onClick={this.handleMainClick}
        >
          <div className="ExpandingSideNav__itemLogo" />
          <div className="ExpandingSideNav__itemTitle">
            {name}
          </div>
          <div
            className="ExpandingSideNav__itemLogo ExpandingSideNav__expander"
            onClick={this.toggleExpand}
            onKeyPress={this.toggleExpand}
            role="button"
            tabIndex={0}
          />
        </NavLink>
        { this.renderChildren() }
      </div>
    );
  }
}

ExpandingSideNav.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  mainTo: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subNavs: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  toggleCollapse: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isCollapsed: UiSelectors.SideNav.isCollapsed(state),
});

export default connect(mapStateToProps, {
  toggleCollapse: UiActions.toggleSidenav,
})(ExpandingSideNav);
