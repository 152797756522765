import makeActionCreator from '../helpers/makeActionCreator';

export const actionTypes = {
  CLEAR: '@ui/CLEAR',
  TOGGLE_SIDENAV: '@dr/TOGGLE_SIDENAV',
  SET_SIDENAV: '@dr/SET_SIDENAV',
};

export const clear = makeActionCreator(actionTypes.CLEAR);
export const toggleSidenav = makeActionCreator(actionTypes.TOGGLE_SIDENAV);
export const setSidenav = makeActionCreator(actionTypes.SET_SIDENAV, 'collapsed');
