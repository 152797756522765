import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  all,
} from 'redux-saga/effects';
import api from '../api';
import { MessagesActions } from '../actions';
import { getTokens } from '../reducers/oauth';
import * as MessagesSelectors from '../reducers/messages'; 
import { toError } from '../../utils';

const { actionTypes } = MessagesActions;

function* fetchMessages(filter = 'all', limitArgs, offsetArgs) {
  try {
    yield put(MessagesActions.fetchMessagesRequest());

    const { accessToken } = yield select(getTokens);

    let messages;
    let limit = limitArgs;
    let offset = offsetArgs;

    if (filter === 'past') {
      messages = yield select(MessagesSelectors.getRawPastMessages);
    } else {
      messages = yield select(MessagesSelectors.getRawUpcomingMessages);
    }

    const { limit: limitM, offset: offsetM } = messages;

    if (limitArgs === undefined && offsetArgs === undefined && limitM !== undefined && offsetM !== undefined) {
      limit = limitM;
      offset = offsetM;
    }

    const response = yield call(api.messages.getMessages, accessToken, filter, limit, offset);

    yield put(MessagesActions.fetchMessagesSuccess(response, filter));

    for (let i = 0; i < response.notifications.length; i += 1) {
      yield put(MessagesActions.fetchMessageSuccess(response.notifications[i]));
    }
  } catch (err) {
    yield put(MessagesActions.fetchMessagesFailure(toError(err)));
  }
}

function* sendMessage(groupId, message, time = new Date()) {
  try {
    yield put(MessagesActions.sendMessageRequest());

    const { accessToken } = yield select(getTokens);

    yield call(api.messages.sendByGroupId, groupId, time.toISOString(), message, accessToken);

    yield put(MessagesActions.sendMessageSuccess());

    yield call(fetchMessages);
  } catch (err) {
    yield put(MessagesActions.sendMessageFailure(toError(err)));
  }
}

function* fetchMessage(messageId) {
  try {
    yield put(MessagesActions.fetchMessageRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.messages.getMessage, messageId, accessToken);

    yield put(MessagesActions.fetchMessageSuccess(response));
  } catch (err) {
    yield put(MessagesActions.fetchMessagesFailure(toError(err)));
  }
}

function* fetchMessageStatus(messageId) {
  try {
    yield put(MessagesActions.fetchMessageStatusRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.messages.getMessageStatus, messageId, accessToken);

    yield put(MessagesActions.fetchMessageStatusSuccess(response));
  } catch (err) {
    yield put(MessagesActions.fetchMessageStatusFailure(toError(err)));
  }
}

function* fetchMessagesPage(which, filter) {
  try {
    let messages;
    let newOffset;

    if (which === 'next') {
      yield put(MessagesActions.fetchMessagesNextPageRequest());
    } else {
      yield put(MessagesActions.fetchMessagesPrevPageRequest());
    }

    if (filter === 'past') {
      messages = yield select(MessagesSelectors.getRawPastMessages);
    } else {
      messages = yield select(MessagesSelectors.getRawUpcomingMessages);
    }

    const { limit, offset } = messages;

    if (which === 'next') {
      newOffset = limit + offset;
    } else {
      newOffset = offset - limit;
    }

    yield call(fetchMessages, filter, limit, newOffset);
  } catch (err) {
    console.log('failed fetching messages pages');
  }
}

function* performByAction(action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SEND_MESSAGE:
      return yield call(sendMessage, payload.groupId, payload.message, payload.time);
    case actionTypes.FETCH_MESSAGES:
      return yield call(fetchMessages, payload.filter);
    case actionTypes.FETCH_MESSAGE:
      return yield call(fetchMessage, payload.messageId);
    case actionTypes.FETCH_MESSAGE_STATUS:
      return yield call(fetchMessageStatus, payload.messageId);
    case actionTypes.FETCH_MESSAGES_NEXT_PAGE:
      return yield call(fetchMessagesPage, 'next', payload.filter);
    case actionTypes.FETCH_MESSAGES_PREV_PAGE:
      return yield call(fetchMessagesPage, 'prev', payload.filter);
    default:
      return null;
  }
}

function* messagesSaga() {
  yield all([
    takeEvery(
      [
        actionTypes.FETCH_MESSAGE,
        actionTypes.FETCH_MESSAGE_STATUS,
      ],
      performByAction,
    ),
    takeLatest(
      [
        actionTypes.SEND_MESSAGE,
        actionTypes.FETCH_MESSAGES,
        actionTypes.FETCH_MESSAGES_NEXT_PAGE,
        actionTypes.FETCH_MESSAGES_PREV_PAGE,
      ],
      performByAction,
    ),
  ]);
}

export default messagesSaga;