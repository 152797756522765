import { actionTypes } from '../actions/groups';

function GroupsReducer(state = {}, action) {
  const {
    type,
    payload,
  } = action;
  const groups = state.groups ? state.groups.slice() : [];

  switch (type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.FETCH_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: payload.groups,
      };
    case actionTypes.FETCH_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error.message,
      };
    case actionTypes.FETCH_GROUPS_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case actionTypes.FETCH_GROUPS_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          [payload.groupId]: payload.users,
        },
      };

    case actionTypes.FETCH_GROUPS_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: payload.error.message,
        },
      };
    case actionTypes.CREATE_GROUP_SUCCESS:
      groups.splice(payload.group.id, 1, {
        ...state.groups[payload.group.id],
        ...payload.group,
      });

      return {
        ...state,
        groups,
      };
    case actionTypes.UPDATE_GROUP_SUCCESS:
      console.log('groups ran');
      groups.splice(payload.groupId, 1, {
        ...state.groups[payload.groupId],
        ...payload.group,
      });

      return {
        ...state,
        groups,
      };
    default:
      return state;
  }
}

export default GroupsReducer;
export const isLoading = state => state.groups.loading;
export const groups = state => state.groups.groups || [];
export const users = state => state.groups.users || {};
export const isLoadingUsers = state => state.groups.users ? state.groups.users.loading : false;
