import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Loading } from '../../components';
import { SimpleDataTable, UserLink } from '../../components/shared/ui';
import * as DrSelectors from '../../redux/reducers/dr';
import './DrEventDetailed.css';

class DrEventDetailed extends Component {
  constructor(props) {
    super(props);

    this.renderEventDetails = this.renderEventDetails.bind(this);
    this.renderEventUsers = this.renderEventUsers.bind(this);
  }

  renderEventDetails() {
    const { events, match: { params: { eventId } } } = this.props;

    const selectedEvent = events[eventId];

    return (
      <SimpleDataTable
        header="Event Details"
        className="EventDetails"
        data={[
          {
            title: 'Event ID',
            content: selectedEvent.id,
          },
          {
            title: 'Start Time',
            content: new Date(selectedEvent.startTime).toLocaleString(),
          },
          {
            title: 'End Time',
            content: new Date(selectedEvent.endTime).toLocaleString(),
          },
          {
            title: 'Groups Targeted',
            content: selectedEvent.groups.join(', '),
          },
        ]}
      />
    );
  }

  renderEventUsers() {
    const { users, match: { params: { eventId } } } = this.props;

    const selectedUsers = users[eventId];

    if (!selectedUsers) return <Loading />;

    return (
      <div className="EventUsers">
        <h3>Event Users</h3>
        <Table striped>
          <thead>
            <tr>
              <th>
                User ID
              </th>
              <th>
                Participating
              </th>
            </tr>
          </thead>
          <tbody>
            {
              selectedUsers.users.map(user => (
                <tr key={user.id}>
                  <td>
                    <UserLink userId={user.id} />
                  </td>
                  <td>
                    { user.optIn ? 'Yes' : 'No' }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    const { events, match: { params: { eventId } } } = this.props;
    const selectedEvent = events[eventId];

    if (!selectedEvent || !selectedEvent.groups) return <Loading />;

    return (
      <div className="DrEventDetailed">
        { this.renderEventDetails() }
        { this.renderEventUsers() }
      </div>
    );
  }
}

DrEventDetailed.propTypes = {
  events: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  events: DrSelectors.getEvents(state),
  users: DrSelectors.getUsers(state),
});

export default connect(mapStateToProps)(DrEventDetailed);
