import OAuthReducer from './oauth';
import UserReducer from './user';
import GroupsReducer from './groups';
import UsersReducer from './users';
import MessagesReducer from './messages';
import UIReducer from './ui';
import DrReducer from './dr';
import OidUploadReducer from './oidUpload';

const RootReducer = {
  oauth: OAuthReducer,
  user: UserReducer,
  groups: GroupsReducer,
  users: UsersReducer,
  messages: MessagesReducer,
  ui: UIReducer,
  dr: DrReducer,
  oidUpload: OidUploadReducer,
};

export default RootReducer;
